import React from 'react';

import { ProductRelatedItem } from './types';
import { TrashIcon } from '../assets/TrashIcon';
import { Dropdown } from '../component/Dropdown';
import { SearchableDropdown } from '../component/SearchableDropdown';
import { generateUniqueId } from '../utils';

export const ItemSelector = ({
  items,
  selectedItems,
  setSelectedItems,
  fixedItems = [],
}: {
  items: string[];
  selectedItems: ProductRelatedItem[];
  setSelectedItems: React.Dispatch<React.SetStateAction<ProductRelatedItem[]>>;
  fixedItems?: string[];
}) => {
  const availableItems = items.filter(
    (item) => !selectedItems.some((selected) => selected.sku === item)
  );

  const handleAddItem = (index: number, sku: string, quantity: number) => {
    const newItems = [...selectedItems];
    newItems[index] = {
      id: newItems[index]?.id || generateUniqueId(),
      sku,
      quantity,
    };
    setSelectedItems(newItems);
  };

  const handleAddNewEntry = () => {
    setSelectedItems([...selectedItems, { id: generateUniqueId(), sku: '', quantity: 0 }]);
  };

  const handleRemoveItem = (index: number) => {
    const newItems = [...selectedItems];
    newItems.splice(index, 1);
    setSelectedItems(newItems);
  };

  return (
    <div>
      {selectedItems.map((item, index) => (
        <div key={item.id}>
          <div className="flex py-2">
            <div className="w-1/5 pr-2">
              <Dropdown
                options={Array.from(
                  { length: fixedItems.includes(item.sku) ? 101 : 100 },
                  (_, i) => i + (fixedItems.includes(item.sku) ? 0 : 1)
                ).map((quantity) => ({
                  value: quantity.toString(),
                  label: quantity.toString(),
                }))}
                selectedOption={
                  item.quantity === 0 && !fixedItems.includes(item.sku)
                    ? undefined
                    : {
                        value: item.quantity.toString(),
                        label: item.quantity.toString(),
                      }
                }
                defaultText="Qty"
                onSelect={(newSelection) =>
                  handleAddItem(index, item.sku, Number(newSelection.value))
                }
                noDefaultSelection
              />
            </div>
            <div className="w-3/5">
              <SearchableDropdown
                options={availableItems.map((availableItem) => ({
                  value: availableItem,
                  label: availableItem,
                }))}
                value={item.sku.length !== 0 ? { value: item.sku, label: item.sku } : null}
                onChange={(newSelection: any) =>
                  handleAddItem(index, newSelection.value, item.quantity)
                }
                isDisabled={fixedItems.includes(item.sku)}
              />
            </div>
            <div className="w-1/5 p-2 items-center">
              {fixedItems.includes(item.sku) ? (
                <div />
              ) : (
                <TrashIcon
                  className="inline w-4 h-4 mx-1 cursor-pointer"
                  onClick={() => handleRemoveItem(index)}
                />
              )}
            </div>
          </div>
        </div>
      ))}
      <button className="underline text-xs" onClick={handleAddNewEntry}>
        Add Item
      </button>
    </div>
  );
};
