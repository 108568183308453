import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { OneTimeProductContext } from '.';
import { axios } from '../../api';
import { digitsOptions } from '../../dropdownOptions';
import { OneTimeOrderProduct, useGetOrderSummary } from '../../hooks/getOrderSummary';
import { Option } from '../../pages/types';
import { BOTTOM_TOAST, generateErrorMsg } from '../../toastUtils';
import { Button } from '../Button';
import { ConfirmationModal } from '../ConfirmationModal';
import { Dropdown } from '../Dropdown';
import { OrderSummary } from '../OrderSummary';

export const OneTimeProductStep2 = () => {
  const {
    customer,
    order,
    dogs,
    selectedDog,
    selectedProducts,
    setSelectedProducts,
    navigateBetweenSteps,
    isEverythingSelected,
    onConfirm,
    scheduledDate,
  } = useContext(OneTimeProductContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const discount = order.discounts?.length > 0 ? [order.discounts[0].discount.code] : [];

  const [discountCodes, setDiscountCodes] = useState<string[]>(discount);

  const { summary, orderProducts, setOrderProducts, reCallOrderSummary, discount_codes, error } =
    useGetOrderSummary({
      customer,
      selectedProducts,
      selectedDog,
      discount_codes: discountCodes,
      existingOrderProducts: order.products,
      dogs,
      order_id: order.id,
    });

  const deleteProduct = (code: string) => {
    setSelectedProducts((prev: Option[]) => prev.filter((item) => item.label !== code));
    setOrderProducts((prev) => prev.filter((item) => item.product_code !== code));
  };

  const updateQuantityProduct = (code: string, option: Option) => {
    setOrderProducts((prev) =>
      prev.map((item) => (item.product_code === code ? { ...item, quantity: +option.value } : item))
    );
    reCallOrderSummary();
  };

  useEffect(() => {
    navigateBetweenSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEverythingSelected]);

  useEffect(() => {
    if (error?.discount_codes) {
      setDiscountCodes([]);
    }
  }, [error?.discount_codes]);

  const onCreate = () => {
    const apiOrderProducts = [
      ...orderProducts,
      ...summary.summaryProducts.existingOrderProducts,
    ].map((orderProduct: OneTimeOrderProduct) => {
      return {
        pet_plan: orderProduct.pet_plan,
        product_code: orderProduct.product_code,
        quantity: orderProduct.quantity,
        type: orderProduct.type,
      };
    });
    axios
      .put(`orders/${order.id}`, {
        account: customer.id,
        order_products: apiOrderProducts,
        discount_codes: discountCodes,
        order_type: order.order_type,
        order_id: order.id,
      })
      .then(() => {
        setShowConfirmModal(false);
        onConfirm();
        let toastMsg = `We added a one-time product to this upcoming order.`;
        if (orderProducts.length > 1) {
          toastMsg = `We added ${orderProducts.length} one-time products to this upcoming order.`;
        }
        toast.success(toastMsg, BOTTOM_TOAST);
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  return (
    <>
      <div className="bg-white mt-6 p-6 pb-2 max-w-[1000px] w-[100%] max-h-[500px] overflow-scroll border border-lightGray relative">
        <h2 className="font-bold text-left mb-2">Order Summary</h2>
        <h2 className="font-bold mb-2 text-center">{selectedDog?.label}</h2>
        <div className="flex flex-col">
          <span className="font-semibold">One-Time Products</span>
          {orderProducts.map((product) => (
            <div key={product.product_code} className="pt-1 pb-1 flex items-center justify-between">
              <div className="flex items-center">
                <div className="w-[70px] mr-[10px]">
                  <Dropdown
                    options={digitsOptions}
                    onSelect={(newSelection) =>
                      updateQuantityProduct(product.product_code, newSelection)
                    }
                  />
                </div>
                {product.product_code}
                <a
                  className="cursor-pointer text-red-600 pl-[2px] pr-[8px]"
                  onClick={() => deleteProduct(product.product_code)}
                >
                  X
                </a>
              </div>
              <span className="font-semibold">{product.price && `$${product.price}`}</span>
            </div>
          ))}
        </div>
        <div className="w-full h-[1px] bg-lightGray mt-2 mb-3" />
        <OrderSummary
          summary={summary}
          discountCodes={discount_codes}
          setDiscountCodes={setDiscountCodes}
        />
      </div>
      <div className="mt-[30px] w-fill flex justify-center">
        <Button
          label="Add One-Time Product(s) to Order"
          variant="primary"
          onClick={() => setShowConfirmModal(true)}
        />
      </div>
      {showConfirmModal && (
        <ConfirmationModal
          title="Are you sure you want to proceed?"
          message={`By clicking "Confirm" below,
            you will add this product on a one-time order basis to this upcoming order scheduled for ${scheduledDate}`}
          confirmLabel="Confirm"
          onConfirm={onCreate}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
};
