import React from 'react';
import { toast } from 'react-toastify';

import { CopyIcon } from '../assets/CopyIcon';
import { BOTTOM_TOAST } from '../toastUtils';

interface CopyTextProps {
  label: string;
  textToCopy: string;
  toastText?: string;
}

export const CopyText = ({ label, textToCopy, toastText = 'Copied text' }: CopyTextProps) => {
  return (
    <a
      onClick={() => {
        navigator.clipboard.writeText(textToCopy);
        toast.info(toastText, BOTTOM_TOAST);
      }}
      className="flex items-center space-x-1"
    >
      <span>{label}</span>
      <CopyIcon className="cursor-pointer w-4 h-4 text-black" />
    </a>
  );
};
