import * as React from 'react';
import { useEffect, useState } from 'react';

import { ItemLotCodeInput } from './ItemLotCodeInput';
import { ResolutionStatusOptions, SymptomTypeOptions } from './constants';
import { ClaimFormProps } from './types';

export const AdverseReactionForm = ({
  formData,
  setFormData,
  setIsValid,
  product,
}: ClaimFormProps) => {
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    if (formData.adverseReactionLotCodes) {
      return;
    }
    setFormData({
      ...formData,
      adverseReactionLotCodes: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isValidForm =
      !!formData.adverseReactionSymptoms?.length &&
      formData.adverseReactionVetVisitRequired !== undefined &&
      (formData.adverseReactionVetVisitRequired === 'YES'
        ? formData.adverseReactionVetBillRequested !== undefined
        : true) &&
      (formData.adverseReactionSymptoms.includes('OTHER')
        ? !!formData.adverseReactionAdditionalNotes
        : true) &&
      formData.adverseReactionLotCodes !== undefined &&
      Object.values(formData.adverseReactionLotCodes).some((lotCode) => lotCode !== undefined) &&
      !Object.values(errors).some((error) => error !== '');
    setIsValid(isValidForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleLotCodeChange = (sku: string, lotCode: string, toDelete?: boolean) => {
    if (toDelete) {
      const newLotCodes = { ...formData.adverseReactionLotCodes };
      delete newLotCodes[sku];
      setFormData({
        ...formData,
        adverseReactionLotCodes: newLotCodes,
      });
    } else {
      setFormData({
        ...formData,
        adverseReactionLotCodes: { ...formData.adverseReactionLotCodes, [sku]: lotCode },
      });
    }
  };

  const handleSymptomChange = (symptomValue: string) => {
    const currentSymptoms = formData.adverseReactionSymptoms || [];
    const updatedSymptoms = currentSymptoms.includes(symptomValue)
      ? currentSymptoms.filter((s) => s !== symptomValue)
      : [...currentSymptoms, symptomValue];

    if (currentSymptoms.includes('OTHER') && !updatedSymptoms.includes('OTHER')) {
      setFormData({
        ...formData,
        adverseReactionSymptoms: updatedSymptoms,
        adverseReactionAdditionalNotes: undefined,
      });
      return;
    }
    setFormData({
      ...formData,
      adverseReactionSymptoms: updatedSymptoms,
    });
  };

  return (
    <div className="flex flex-col space-y-4">
      <ItemLotCodeInput
        items={product.items}
        lotCodes={formData.adverseReactionLotCodes}
        onLotCodeChange={handleLotCodeChange}
        isUnkibbleMeal={product.isMealProduct && !product.is_frozen}
        errors={errors}
        setErrors={setErrors}
      />

      <div className="flex flex-col space-y-2">
        <label className="block text-lg font-semibold text-gray-700 mb-1">
          Vet visit required?
        </label>
        <div className="space-y-2">
          {ResolutionStatusOptions.map((option) => (
            <div key={option.value} className="flex items-center space-x-2">
              <input
                id={`vet-visit-${option.value}`}
                type="radio"
                name="adverse_reaction_vet_visit_required"
                value={option.value}
                checked={formData.adverseReactionVetVisitRequired === option.value}
                onChange={() => {
                  const updates = {
                    YES: {
                      adverse_reaction_vet_bill_requested: undefined,
                      adverse_reaction_vet_bill_cost: 0,
                    },
                    NO: {
                      adverse_reaction_vet_bill_requested: false,
                      adverse_reaction_vet_bill_cost: undefined,
                    },
                    UNKNOWN: {
                      adverse_reaction_vet_bill_requested: undefined,
                      adverse_reaction_vet_bill_cost: undefined,
                    },
                  };
                  setFormData({
                    ...formData,
                    adverseReactionVetVisitRequired: option.value,
                    ...updates[option.value as keyof typeof updates],
                  });
                }}
                className="h-4 w-4 text-blue-600"
              />
              <label htmlFor={`vet-visit-${option.value}`} className="cursor-pointer">
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>

      {formData.adverseReactionVetVisitRequired === 'YES' && (
        <div className="flex flex-col space-y-2">
          <label className="block text-lg font-semibold text-gray-700 mb-1">
            Vet bill compensation requested?
          </label>
          <div className="flex flex-col">
            <div className="inline-flex items-center">
              <input
                id="vet-bill-yes"
                type="radio"
                checked={formData.adverseReactionVetBillRequested}
                onChange={() =>
                  setFormData({
                    ...formData,
                    adverseReactionVetBillRequested: true,
                  })
                }
                className="h-4 w-4 text-blue-600"
              />
              <label htmlFor="vet-bill-yes" className="ml-2 cursor-pointer">
                Yes
              </label>
            </div>
            <div className="inline-flex items-center">
              <input
                id="vet-bill-no"
                type="radio"
                checked={formData.adverseReactionVetBillRequested === false}
                onChange={() =>
                  setFormData({
                    ...formData,
                    adverseReactionVetBillRequested: false,
                  })
                }
                className="h-4 w-4 text-blue-600"
              />
              <label htmlFor="vet-bill-no" className="ml-2 cursor-pointer">
                No
              </label>
            </div>
          </div>
        </div>
      )}

      {formData.adverseReactionVetBillRequested && (
        <div className="flex flex-col space-y-2">
          <label className="block text-lg font-semibold text-gray-700 mb-1">Vet Bill Amount</label>
          <div className="relative">
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
              $
            </span>
            <input
              type="number"
              min="0"
              step="0.01"
              value={formData.adverseReactionVetBillCost || ''}
              onChange={(e) => {
                const value = e.target.value;
                const decimalPlaces = value.includes('.') ? value.split('.')[1].length : 0;

                if (decimalPlaces <= 2) {
                  setFormData({
                    ...formData,
                    adverseReactionVetBillCost: parseFloat(value),
                  });
                }
              }}
              className="w-full pl-7 p-2 border rounded"
              placeholder="0.00"
            />
          </div>
        </div>
      )}

      <div className="flex flex-col space-y-4">
        <label className="block text-lg font-semibold text-gray-700 mb-1">Select Symptom(s)</label>
        <div className="space-y-2">
          {SymptomTypeOptions.map((option) => (
            <div key={option.value} className="flex items-center space-x-2">
              <input
                id={`symptom-${option.value}`}
                type="checkbox"
                checked={formData.adverseReactionSymptoms?.includes(option.value) || false}
                onChange={() => handleSymptomChange(option.value)}
                className="h-4 w-4 text-blue-600 rounded"
              />
              <label htmlFor={`symptom-${option.value}`} className="cursor-pointer">
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>

      {formData.adverseReactionSymptoms?.includes('OTHER') && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Additional Notes</label>
          <textarea
            value={formData.adverseReactionAdditionalNotes || ''}
            onChange={(e) =>
              setFormData({
                ...formData,
                adverseReactionAdditionalNotes: e.target.value,
              })
            }
            className="w-full p-2 border rounded min-h-[100px]"
            placeholder="Please describe the symptoms..."
          />
        </div>
      )}
    </div>
  );
};
