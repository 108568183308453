import * as React from 'react';
import { useState, useEffect } from 'react';

import { TrashIcon } from '../../assets/TrashIcon';
import { Item } from '../../pages/types';
import { TextInput } from '../TextInput';

interface ItemLotCodeInputProps {
  items: Item[];
  lotCodes?: Record<string, string>;
  onLotCodeChange: (sku: string, lotCode: string, to_delete?: boolean) => void;
  isUnkibbleMeal?: boolean;
  errors: Record<string, string>;
  setErrors: (errors: Record<string, string>) => void;
  onDelete?: () => void;
}

export const ItemLotCodeInput = ({
  items,
  lotCodes = {},
  onLotCodeChange,
  isUnkibbleMeal,
  errors,
  setErrors,
  onDelete,
}: ItemLotCodeInputProps) => {
  const [selectedItems, setSelectedItems] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (!items || !lotCodes) return;

    // Initialize selected state for items that have lot codes
    const initialSelected = Object.keys(lotCodes).reduce((acc, sku) => {
      acc[sku] = sku in lotCodes;
      return acc;
    }, {} as Record<string, boolean>);
    setSelectedItems(initialSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateLotCode = (sku: string, lotCode: string): boolean => {
    if (isUnkibbleMeal && selectedItems[sku] && lotCode !== '') {
      const regex = /^(A1|MM)\s+\d{5}$/;
      return regex.test(lotCode);
    }
    return true;
  };

  const handleCheckboxChange = (sku: string) => {
    const newSelectedItems = {
      ...selectedItems,
      [sku]: !selectedItems[sku],
    };
    setSelectedItems(newSelectedItems);

    // Clear lot code when unchecking
    if (!newSelectedItems[sku]) {
      onLotCodeChange(sku, '', true);
      const newErrors = { ...errors };
      delete newErrors[sku];
      setErrors(newErrors);
    } else {
      onLotCodeChange(sku, '');
    }
  };

  const handleLotCodeChange = (sku: string, value: string) => {
    onLotCodeChange(sku, value);
    if (isUnkibbleMeal) {
      const isValid = validateLotCode(sku, value);
      setErrors({
        ...errors,
        [sku]: isValid ? '' : 'Lot code must be A1 or MM followed by space and 5 digits',
      });
    }
  };

  if (!items || !lotCodes) {
    return (
      <div className="flex flex-col space-y-4">
        <h2 className="text-lg font-semibold">Items affected by this issue</h2>
        <div>No items found</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold">Items affected by this issue</h2>
        {onDelete && (
          <button
            onClick={onDelete}
            className="text-gray-900 hover:text-gray-700"
            aria-label="Remove issue"
          >
            <TrashIcon className="h-5 w-5 cursor-pointer" />
          </button>
        )}
      </div>

      <div className="space-y-2">
        {items.map(({ sku, name }) => (
          <div key={sku} className="space-y-2">
            <div className="flex items-center space-x-2">
              <input
                id={`lot-code-${sku}`}
                type="checkbox"
                checked={selectedItems[sku] || false}
                onChange={() => handleCheckboxChange(sku)}
                className="h-4 w-4 text-blue-600"
              />
              <label htmlFor={`lot-code-${sku}`} className="cursor-pointer">
                {`${sku} (${name})`}
              </label>
            </div>
            {selectedItems[sku] && (
              <div className="ml-6">
                <TextInput
                  placeholder="Enter lot code"
                  value={lotCodes[sku] || ''}
                  setTextChange={(value) => handleLotCodeChange(sku, value)}
                  error={errors[sku]}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
