import { parseNumber } from './financialUtils';
import { formatingOptions } from './formatingOptions';
import {
  Option,
  Fulfillment,
  FulfillmentProduct,
  Order,
  OrderFulfillmentProduct,
  OrderProduct,
  OrderStatus,
  OrderType,
  Orders,
  ProductCodesResponse,
  ProductType,
} from './pages/types';
import { MULTIPLE_MEAL_ONE_TIME_PRODUCTS } from './toastUtils';

export const getOrder = (orders: Orders | undefined, orderId: string | undefined): any => {
  if (!orders) {
    return null;
  }
  return (
    orders.upcoming.find((order: Order) => order.id === orderId) ||
    orders.processing.find((order: Order) => order.id === orderId) ||
    orders.past.find((order: Order) => order.id === orderId)
  );
};

export const getFulfilledOrders = (order: Orders): Order[] => {
  const orders = [...order.past, ...order.processing, ...order.upcoming];
  return orders.filter(
    (order: Order) =>
      order.status === OrderStatus.COMPLETE ||
      order.status === OrderStatus.PARTIALLY_FULFILLED ||
      order.status === OrderStatus.FULFILLED ||
      order.status === OrderStatus.HISTORICAL ||
      order.status === OrderStatus.RUSH_ORDER
  );
};

export const getChargedOrders = (order: Orders): Order[] => {
  const orders = [...order.past, ...order.processing, ...order.upcoming];
  return orders.filter((order: Order) => !!order.charged);
};

export const getFulfilledOrderCount = (order: Orders): number => {
  return getFulfilledOrders(order).length;
};

export const getReplacedOrderCount = (order: Orders): number => {
  const orders = [...order.past, ...order.processing, ...order.upcoming];
  return orders.filter((order: Order) => order.order_type === OrderType.REPLACEMENT).length;
};

export const getCxCodeCount = (orderInput: Orders): number => {
  const orders = getChargedOrders(orderInput);
  let cxCodeCount = 0;
  for (const order of orders) {
    for (const discount of order.discounts) {
      if (discount?.discount) {
        if (discount.discount?.code) {
          if (
            ['10POFFX1', '30POFFX1', 'CX-ACCOM'].some((prefix) =>
              discount.discount.code.startsWith(prefix)
            )
          ) {
            cxCodeCount++;
          }
        }
      }
    }
  }
  return cxCodeCount;
};

export const getWinbackDiscountEligiblility = (customerOrders: Orders): string => {
  const cutoffWindow = 90;
  const cutoffDate = new Date();
  cutoffDate.setDate(cutoffDate.getDate() - cutoffWindow);

  const ordersChargedAfterCutoff = [...customerOrders.processing, ...customerOrders.past].filter(
    (order) => !!order.charged && new Date(order.charged) > cutoffDate
  );

  return ![...customerOrders.upcoming, ...ordersChargedAfterCutoff].find((order) =>
    order.discounts.find((orderDiscount) => orderDiscount.discount.code.startsWith('WB-'))
  )
    ? 'Yes'
    : 'No';
};

export const validateOneTimeOrderProducts = (products: ProductCodesResponse[]) => {
  let mealProductCount = 0;
  products.forEach((product) => {
    if (product.type === ProductType.MEAL) {
      mealProductCount++;
    }
    if (mealProductCount > 1) {
      throw Error(MULTIPLE_MEAL_ONE_TIME_PRODUCTS);
    }
  });
};

export const filterProductsByPetMealType = (
  fullProducts: ProductCodesResponse[],
  selectedProducts?: Option[]
) => {
  if (!selectedProducts?.length) {
    return formatingOptions(fullProducts, { value: 'id', label: 'code' });
  }
  const freshCodes = ['CLIP', 'MEAL-F', 'FRESH'];
  const unKibbleCodes = ['SCOOP', 'MEAL-U', 'U-', 'UNKIBBLE'];
  const productsIncludeFresh = selectedProducts?.some((productOption) =>
    freshCodes.some((code) => productOption.label.includes(code))
  );
  const productsIncludeUnkibble = selectedProducts?.some((productOption) =>
    unKibbleCodes.some((code) => productOption.label.includes(code))
  );

  const filteredProducts = fullProducts.filter((product) => {
    if (productsIncludeFresh) {
      return !unKibbleCodes.some((code) => product.code.includes(code));
    } else if (productsIncludeUnkibble) {
      return !freshCodes.some((code) => product.code.includes(code));
    }
    return true;
  });
  return formatingOptions(filteredProducts, { value: 'id', label: 'code' });
};

export const mapFulfillments = (order: Order): OrderFulfillmentProduct[] =>
  order.fulfillments
    .map((fulfillment: Fulfillment) =>
      fulfillment.products.map((product: FulfillmentProduct) => ({
        ...product,
        pet_id: product.pet_id || fulfillment.pet,
        isMealProduct: product.type === 'MEAL',
        tax_amount: parseNumber(
          order.products.find((orderProduct: OrderProduct) => orderProduct.code === product.code)
            ?.tax_amount
        ),
        fulfillment,
        order,
        unit_price: product.price.toFixed(2),
        discount_amount: parseNumber(product.discount_amount).toFixed(2),
      }))
    )
    .flat()
    .sort((a: OrderFulfillmentProduct, b: OrderFulfillmentProduct) =>
      a.fulfillment.id.localeCompare(b.fulfillment.id)
    );
