import * as React from 'react';
import { useEffect } from 'react';

import { FeedingGuideIssueTypeOptions } from './constants';
import { ClaimFormProps } from './types';

export const FeedingGuideForm = ({
  formData,
  setFormData,
  setIsValid,
  product,
}: ClaimFormProps) => {
  useEffect(() => {
    const isValidForm = formData.feedingGuideIssueType !== undefined;
    setIsValid(isValidForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-2">
        <label className="block text-lg font-semibold text-gray-700 mb-1">
          What was the issue with their feeding guide?
        </label>
        <div className="space-y-2">
          {FeedingGuideIssueTypeOptions.map((option) => (
            <div key={option.value} className="flex items-center space-x-2">
              <input
                id={`feeding-guide-${option.value}`}
                type="radio"
                name="feeding_guide_issue_type"
                value={option.value}
                checked={formData.feedingGuideIssueType === option.value}
                onChange={() =>
                  setFormData({
                    ...formData,
                    feedingGuideIssueType: option.value,
                  })
                }
                className="h-4 w-4 text-blue-600"
              />
              <label htmlFor={`feeding-guide-${option.value}`} className="cursor-pointer">
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
