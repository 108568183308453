import * as React from 'react';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AdverseReactionForm } from './AdverseReactionForm';
import { ClaimBasicInfo } from './ClaimBasicInfo';
import { FeedingGuideForm } from './FeedingGuideForm';
import { MispackForm } from './MispackForm';
import { ProductQualityForm } from './ProductQualityForm';
import { RoofForm } from './RoofForm';
import { WarmBoxForm } from './WarmBoxForm';
import { ClaimType } from './constants';
import { ClaimFormData, MappedFulfillment } from './types';
import { createClaim, updateClaim } from '../../api/claims';
import { Close } from '../../assets/Close';
import { DownArrow } from '../../assets/DownArrow';
import { Spinner } from '../../assets/Spinner';
import { useGetCustomer } from '../../hooks/getCustomer';
import { useFetchClaim } from '../../hooks/useFetchClaim';
import { getOrder, mapFulfillments } from '../../orderUtils';
import { Order, ProductType } from '../../pages/types';
import { BOTTOM_TOAST } from '../../toastUtils';
import { Button } from '../Button';
import { ConfirmationModal } from '../ConfirmationModal';
import { NotFound } from '../NotFound';

export const ClaimForm = () => {
  const navigate = useNavigate();

  const {
    claimId,
    customerId: rawCustomerId,
    orderId,
    productCode,
  } = useParams<{
    claimId: string;
    customerId: string;
    orderId: string;
    productCode: string;
  }>();
  const customerId = rawCustomerId?.split('__')[0];
  const customerName = rawCustomerId?.split('__')[1];
  const prevPath = `/customer-lookup/${customerId}__${customerName}/order/${orderId}`;

  const [currentStep, setCurrentStep] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isStepValid, setIsStepValid] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const {
    customer,
    isLoading: isLoadingGetCustomer,
    isError: isErrorGetCustomer,
  } = useGetCustomer(customerId);

  // Get order and mapped fulfillment products
  const order: Order | undefined = useMemo(() => {
    if (!isLoadingGetCustomer && customer && orderId) {
      return getOrder(customer.orders, orderId);
    }
  }, [customer, orderId, isLoadingGetCustomer]);

  const mappedFulfillmentProducts: MappedFulfillment[] = useMemo(() => {
    if (order) {
      return mapFulfillments(order);
    }
    return [];
  }, [order]);

  const product: MappedFulfillment | undefined = useMemo(() => {
    const product = mappedFulfillmentProducts.find((product) => product.code === productCode);
    return product;
  }, [mappedFulfillmentProducts, productCode]);

  const initialFormData: ClaimFormData = useMemo(() => {
    return {
      claimTypes: [],
      gladlyConversationUrl: '',
      photosReceived: undefined,
      resolutionTypes: [],
      productCode,
      fulfillmentId: product?.fulfillment.id,
      petId: product?.pet_id,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const {
    formData,
    setFormData,
    isLoading: isLoadingClaimData,
    isError: isErrorFetchClaim,
  } = useFetchClaim({
    claimId,
    productCode,
    initialFormData,
    onError: () => navigate(prevPath),
    skipFetch: !product,
  });

  const steps = useMemo(() => {
    const steps: { component: React.ComponentType<any>; label?: string }[] = [
      { component: ClaimBasicInfo },
    ];

    if (product && formData.claimTypes.length > 0) {
      const sortedTypes = [...formData.claimTypes].sort();
      sortedTypes.forEach((type) => {
        switch (type) {
          case ClaimType.ADVERSE_REACTION:
            steps.push({ component: AdverseReactionForm, label: 'Adverse reaction' });
            break;
          case ClaimType.FEEDING_GUIDE:
            steps.push({ component: FeedingGuideForm, label: 'Feeding guide issue' });
            break;
          case ClaimType.MISPACK:
            steps.push({
              component: MispackForm,
              label: `Mispack (${
                product?.type !== ProductType.MEAL
                  ? 'Non-meal'
                  : product?.is_frozen
                  ? 'Fresh'
                  : 'UnKibble'
              })`,
            });
            break;
          case ClaimType.PRODUCT_QUALITY:
            steps.push({
              component: ProductQualityForm,
              label: 'Product quality',
            });
            break;
          case ClaimType.ROOF:
            steps.push({ component: RoofForm, label: 'ROOF' });
            break;
          case ClaimType.WARM_BOX:
            steps.push({ component: WarmBoxForm, label: 'Warm Box' });
            break;
        }
      });
    }
    return steps;
  }, [formData.claimTypes, product]);

  const CurrentStepComponent = steps[currentStep].component;
  const currentStepLabel = steps[currentStep].label;
  const isLastStep = currentStep === steps.length - 1;

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (claimId) {
        await updateClaim(claimId, formData);
      } else {
        await createClaim(formData);
      }
      navigate(prevPath);
    } catch (error) {
      toast.error(`Failed to save claim ${error}`, BOTTOM_TOAST);
    } finally {
      setIsSubmitting(false);
      setShowConfirmModal(false);
    }
  };

  if (isLoadingGetCustomer || isLoadingClaimData) {
    return <Spinner />;
  }

  if (isErrorGetCustomer || isErrorFetchClaim || !product) return <NotFound />;

  return (
    <div className="flex justify-center items-start bg-slate50 p-8 min-h-screen">
      <div className="bg-lightGray rounded-lg shadow-md p-4 w-[750px] relative border">
        <Close
          className="absolute top-4 right-4 w-8 h-8 cursor-pointer"
          onClick={() => setShowCancelModal(true)}
        />

        <h1 className="text-xl font-semibold mb-3">
          {claimId ? 'Update claim' : 'Submit new claim'}
          {currentStepLabel && (
            <span className="text-gray-600">
              {' >> '} {currentStepLabel}
            </span>
          )}
        </h1>

        <div className="mb-4">
          <div>
            <span className="font-bold">Customer: </span>
            <span>{`${customer?.first_name} ${customer?.last_name} (${customer?.email})`}</span>
          </div>
          <div>
            <span className="font-bold">Pet: </span>
            <span>{customer?.pets.find((pet) => pet.id === product?.pet_id)?.name}</span>
          </div>
          <div>
            <span className="font-bold">Product Code: </span>
            <span>{product?.code}</span>
          </div>
          <div>
            <span className="font-bold">Product Name: </span>
            <span>{product?.name}</span>
          </div>
        </div>

        <div
          className={`${
            CurrentStepComponent !== ProductQualityForm
              ? 'bg-white rounded-lg shadow-md p-4 relative border'
              : ''
          } `}
        >
          <CurrentStepComponent
            formData={formData}
            setFormData={setFormData}
            setIsValid={setIsStepValid}
            product={product}
          />
        </div>
        <div className="flex justify-between items-center pt-4">
          <div className="w-[100px]">
            {currentStep > 0 && (
              <h3
                className="flex text-lg font-semibold items-center cursor-pointer"
                onClick={() => {
                  setCurrentStep(currentStep - 1);
                }}
              >
                <div className="w-[20px] h-[28px] overflow-hidden relative">
                  <DownArrow className="absolute left-[-15px] top-[-2px] w-[40px] h-[40px] rotate-90" />
                </div>
                Back
              </h3>
            )}
          </div>
          <Button
            variant={isStepValid ? 'primary' : 'secondary'}
            label={isLastStep && currentStep !== 0 ? 'Save' : 'Continue'}
            isDisabled={!isStepValid}
            onClick={() => {
              if (isLastStep) {
                setShowConfirmModal(true);
              } else {
                setCurrentStep(currentStep + 1);
              }
            }}
          />
          <div className="w-[100px]" />
        </div>

        {showCancelModal && (
          <ConfirmationModal
            isProcessLoading={false}
            title="If you exit now, any unsaved changes made will be lost."
            confirmLabel="Confirm"
            onConfirm={() => {
              setShowCancelModal(false);
              navigate(prevPath);
            }}
            onCancel={() => setShowCancelModal(false)}
          />
        )}

        {showConfirmModal && (
          <ConfirmationModal
            isProcessLoading={isSubmitting}
            title={`Are you sure you want to save ${
              claimId ? 'your changes to' : 'submit'
            } this claim?`}
            message="Please review all fields before proceeding. Claims will remain editable after creation, but they cannot be deleted."
            confirmLabel="Confirm"
            onConfirm={handleSubmit}
            onCancel={() => setShowConfirmModal(false)}
          />
        )}
      </div>
    </div>
  );
};
