export enum ClaimType {
  ADVERSE_REACTION = 'ADVERSE_REACTION',
  FEEDING_GUIDE = 'FEEDING_GUIDE',
  MISPACK = 'MISPACK',
  PRODUCT_QUALITY = 'PRODUCT_QUALITY',
  ROOF = 'ROOF',
  WARM_BOX = 'WARM_BOX',
}

export const ClaimTypeOptions = [
  { label: 'Adverse Reaction', value: ClaimType.ADVERSE_REACTION },
  { label: 'Feeding Guide', value: ClaimType.FEEDING_GUIDE },
  { label: 'Mispack', value: ClaimType.MISPACK },
  { label: 'Product Quality', value: ClaimType.PRODUCT_QUALITY },
  { label: 'ROOF', value: ClaimType.ROOF },
  { label: 'Warm Box', value: ClaimType.WARM_BOX },
];

export const ResolutionTypeOptions = [
  { label: 'Added free product(s) to future order', value: 'FREE_PRODUCT' },
  { label: 'Applied discount on future order(s)', value: 'FUTURE_DISCOUNT' },
  { label: 'No resolution offered', value: 'NO_RESOLUTION' },
  { label: 'Offered refund (partial or full)', value: 'REFUND' },
  { label: 'Sent replacement order (partial or full)', value: 'REPLACEMENT' },
];

export const ResolutionStatusOptions = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
  { label: 'Unknown', value: 'UNKNOWN' },
];

export const WarmBoxSilverBagSealedStatusOptions = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
  { label: 'N/A - no bag', value: 'NO_BAG' },
  { label: 'Unknown', value: 'UNKNOWN' },
];

export const WarmBoxGelPackOrDryIcePresentOptions = [
  { label: 'Gel Pack', value: 'GEL_PACK' },
  { label: 'Dry Ice', value: 'DRY_ICE' },
  { label: 'Neither', value: 'NEITHER' },
  { label: 'Unknown', value: 'UNKNOWN' },
];

export const SymptomTypeOptions = [
  { label: 'Blood in stool', value: 'BLOOD_IN_STOOL' },
  { label: 'Choking', value: 'CHOKING' },
  { label: 'Constipation', value: 'CONSTIPATION' },
  { label: 'Death', value: 'DEATH' },
  { label: 'Diarrhea / loose stool', value: 'DIARRHEA' },
  { label: 'Lameness', value: 'LAMENESS' },
  { label: 'Seizures', value: 'SEIZURES' },
  { label: 'Skin / allergic reaction', value: 'SKIN_REACTION' },
  { label: 'Stomach / digestion (details unknown)', value: 'STOMACH' },
  { label: 'Vomit', value: 'VOMIT' },
  { label: 'Other - describe in notes', value: 'OTHER' },
  { label: 'Unknown', value: 'UNKNOWN' },
];

export const FeedingGuideIssueTypeOptions = [
  { label: "Received a different dog's feeding guide", value: 'DIFFERENT_DOG_FEEDING_GUIDE' },
  { label: 'No feeding guide was included in the box', value: 'NO_FEEDING_GUIDE' },
  { label: 'Printing issue with the feeding guide', value: 'PRINTING_ISSUE' },
  { label: 'Unknown', value: 'UNKNOWN' },
];

export const ProductQualityIssueTypeOptions = [
  { label: 'Damaged Product', value: 'DAMAGED_PRODUCT' },
  { label: 'Foreign Contamination', value: 'FOREIGN_CONTAMINATION' },
  { label: 'Pellets Off-spec', value: 'PELLETS_OFF_SPEC' },
  { label: 'Inclusions Off-spec', value: 'INCLUSIONS_OFF_SPEC' },
  { label: 'Other', value: 'OTHER' },
  { label: 'Unknown', value: 'UNKNOWN' },
];

interface QualityIssueOption {
  primary: {
    label: string;
    value: string;
    showCommentField?: boolean;
    commentRequired?: boolean;
  };
  secondary?: {
    label: string;
    value: string;
    showCommentField?: boolean;
    commentRequired?: boolean;
  }[];
}

export const PRODUCT_QUALITY_ISSUES: QualityIssueOption[] = [
  {
    primary: {
      label: 'Damaged Product',
      value: 'DAMAGED_PRODUCT',
    },
    secondary: [
      {
        label: 'Pack / bag seal failed',
        value: 'PACK_SEAL_FAILED',
      },
      {
        label: 'Pack ripped or bag punctured',
        value: 'PACK_RIPPED',
      },
      {
        label: 'Moisture inside package',
        value: 'MOISTURE_INSIDE',
      },
      {
        label: 'Moisture damaged outside of package',
        value: 'MOISTURE_OUTSIDE',
      },
      {
        label: 'Lip of package coming undone',
        value: 'LIP_UNDONE',
      },
      {
        label: 'Staining - inside of container',
        value: 'STAINING_INSIDE',
      },
      {
        label: 'Staining - outside of container',
        value: 'STAINING_OUTSIDE',
      },
    ],
  },
  {
    primary: {
      label: 'Foreign Contamination',
      value: 'FOREIGN_CONTAMINATION',
    },
    secondary: [
      {
        label: 'Organic / bugs',
        value: 'ORGANIC_BUGS',
      },
      {
        label: 'Rocks',
        value: 'ROCKS',
      },
      {
        label: 'Wood',
        value: 'WOOD',
      },
      {
        label: 'Plastic - soft',
        value: 'PLASTIC_SOFT',
      },
      {
        label: 'Plastic - hard',
        value: 'PLASTIC_HARD',
      },
      {
        label: 'Metal',
        value: 'METAL',
      },
      {
        label: 'Mold',
        value: 'MOLD',
      },
      {
        label: 'Paper',
        value: 'PAPER',
      },
    ],
  },
  {
    primary: {
      label: 'Pellets Off-spec',
      value: 'PELLETS_OFF_SPEC',
    },
    secondary: [
      {
        label: 'Texture - crumbles',
        value: 'TEXTURE_CRUMBLES',
      },
      {
        label: 'Texture - too hard',
        value: 'TEXTURE_TOO_HARD',
      },
      {
        label: 'Shape - elongated pellet',
        value: 'SHAPE_ELONGATED',
      },
      {
        label: 'Shape - merged pellets / super chunk',
        value: 'SHAPE_MERGED',
      },
      {
        label: 'Smells off',
        value: 'SMELLS_OFF',
      },
      {
        label: 'General appearance - batch to batch variation',
        value: 'APPEARANCE_VARIATION',
      },
    ],
  },
  {
    primary: {
      label: 'Inclusions Off-spec',
      value: 'INCLUSIONS_OFF_SPEC',
    },
    secondary: [
      {
        label: 'Too few',
        value: 'TOO_FEW',
      },
      {
        label: 'Too many',
        value: 'TOO_MANY',
      },
      {
        label: 'Poorly distributed',
        value: 'POORLY_DISTRIBUTED',
      },
      {
        label: 'General appearance - batch to batch variation',
        value: 'INCLUSION_APPEARANCE',
      },
      {
        label: 'Shape - merged / super inclusion',
        value: 'INCLUSION_MERGED',
      },
    ],
  },
  {
    primary: {
      label: 'Other',
      value: 'OTHER',
    },
  },
  {
    primary: {
      label: 'Unknown',
      value: 'UNKNOWN',
    },
  },
];

export const productQualityIssueOptions = PRODUCT_QUALITY_ISSUES.map(({ primary }) => ({
  value: primary.value,
  label: primary.label,
}));

export const UNKIBBLE_LOT_CODE_PATTERN = /^(A1|MM)\s+\d{5}$/;
