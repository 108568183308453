import React, { useState, useRef } from 'react';

import { DownArrow } from '../assets/DownArrow';
import { useOutsideHandler } from '../hooks/useOutsideHandler';
import { Option } from '../pages/types';

interface MultiSelectProps {
  options: Option[];
  selectedOptions: Option[];
  setSelectedOptions: (selectedOptions: Option[]) => void;
  placeholder?: string;
  error?: string;
  hideSearch?: boolean;
}

export const MultiSelectDropdown = ({
  options,
  selectedOptions,
  setSelectedOptions,
  placeholder = 'Select options',
  error,
  hideSearch = false,
}: MultiSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const wrapperRef = useRef(null);
  useOutsideHandler(wrapperRef, () => {
    setIsOpen(false);
  });

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      value,
      dataset: { label },
    } = event.target;
    if (label && value) {
      if (selectedOptions.some((option) => option.value === value)) {
        setSelectedOptions(selectedOptions.filter((option) => option.value !== value));
      } else {
        setSelectedOptions([...selectedOptions, { label, value }]);
      }
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const selectedLabels = selectedOptions.map((option) => option.label);

  return (
    <div ref={wrapperRef} className="relative inline-block text-left w-full">
      <div className="w-full">
        <div>
          <button
            onClick={toggleDropdown}
            type="button"
            className="inline-flex w-full px-4 py-2 text-sm font-medium bg-secondary border border-black rounded overflow-x-auto"
          >
            <div className="flex justify-between w-full">
              {selectedLabels.length > 0 ? selectedLabels.join(', ') : placeholder}
              <DownArrow className="w-5 h-5" />
            </div>
          </button>
          {error && (
            <p className="text-red-500 text-xs mt-1 font-normal w-full overflow-auto">{error}</p>
          )}
        </div>
      </div>

      {isOpen && (
        <div className="absolute rounded-md border border-black bg-secondary w-full max-h-48 overflow-y-auto z-50">
          {!hideSearch && (
            <div className="p-2">
              <input
                type="text"
                placeholder={placeholder}
                className="w-full border border-black rounded px-2 py-1"
                value={searchTerm}
                onChange={handleSearchInputChange}
              />
            </div>
          )}
          <div className="max-h-36 overflow-y-auto">
            {filteredOptions.map((option) => (
              <label key={option.value} className="px-4 py-2 cursor-pointer items-center flex">
                <input
                  type="checkbox"
                  value={option.value}
                  data-label={option.label}
                  checked={selectedOptions.some(
                    (selectedOpt) => selectedOpt.value === option.value
                  )}
                  onChange={handleOptionChange}
                  className="form-checkbox h-5 w-5 text-indigo-600 mr-2"
                />
                <span>{option.label}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
