import { toast } from 'react-toastify';

import { axios } from './index';
import {
  ClaimFormData,
  ClaimFormDataBackend,
  ProductQualityIssueBackend,
} from '../component/Claim/types';
import { BOTTOM_TOAST } from '../toastUtils';

const formatClaimData = (formData: ClaimFormData): ClaimFormDataBackend => {
  return {
    claim_types: formData.claimTypes,
    gladly_conversation_url: formData.gladlyConversationUrl,
    photos_received: formData.photosReceived,
    resolution_types: formData.resolutionTypes,
    product_code: formData.productCode,
    fulfillment_id: formData.fulfillmentId,
    pet_id: formData.petId,
    adverse_reaction_vet_visit_required: formData.adverseReactionVetVisitRequired,
    adverse_reaction_vet_bill_requested: formData.adverseReactionVetBillRequested,
    adverse_reaction_vet_bill_cost: formData.adverseReactionVetBillCost,
    adverse_reaction_symptoms: formData.adverseReactionSymptoms,
    adverse_reaction_additional_notes: formData.adverseReactionAdditionalNotes,
    adverse_reaction_lot_codes: formData.adverseReactionLotCodes,
    feeding_guide_issue_type: formData.feedingGuideIssueType,
    mispack_have_information_on_what_was_received: formData.mispackHaveInformationOnWhatWasReceived,
    mispack_should_have_received: formData.mispackShouldHaveReceived,
    roof_unkibble_used_correct_scoop: formData.roofUnkibbleUsedCorrectScoop,
    roof_unkibble_fed_correct_number_of_scoops: formData.roofUnkibbleFedCorrectNumberOfScoops,
    roof_unkibble_leveled_off_scoop: formData.roofUnkibbleLeveledOffScoop,
    roof_fresh_fed_correct_number_of_packs: formData.roofFreshFedCorrectNumberOfPacks,
    roof_lot_codes: formData.roofLotCodes,
    warm_box_food_inside_silver_bag: formData.warmBoxFoodInsideSilverBag,
    warm_box_silver_bag_sealed: formData.warmBoxSilverBagSealed,
    warm_box_gel_pack_or_dry_ice_present: formData.warmBoxGelPackOrDryIcePresent,
    mispack_actually_received: formData.mispackActuallyReceived?.map((item) => ({
      [item.sku]: item.quantity,
    })),
    product_quality_issues: formData.productQualityIssues?.map(
      (issue) =>
        ({
          issue_type: issue.issueType,
          sub_issue: issue.subIssue,
          notes: issue.notes,
          lot_codes: issue.lotCodes
            ? Object.fromEntries(
                Object.entries(issue.lotCodes).filter(([_, lotCode]) => lotCode !== undefined)
              )
            : null,
        } as ProductQualityIssueBackend)
    ),
  };
};

export const createClaim = async (formData: ClaimFormData) => {
  try {
    const formattedData = formatClaimData(formData);
    const response = await axios.post('/claims', formattedData);
    toast.success('Claim saved successfully', BOTTOM_TOAST);
    return response.data;
  } catch (error) {
    toast.error(`Failed to save claim: ${error}`, BOTTOM_TOAST);
    throw error;
  }
};

export const updateClaim = async (claimId: string, formData: ClaimFormData) => {
  try {
    const formattedData = formatClaimData(formData);
    const response = await axios.put(`/claims/${claimId}`, formattedData);
    toast.success('Claim updated successfully', BOTTOM_TOAST);
    return response.data;
  } catch (error) {
    toast.error(`Failed to update claim: ${error}`, BOTTOM_TOAST);
    throw error;
  }
};
