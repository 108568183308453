import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ClaimsColumn } from './ClaimsColumn';
import { ConfirmationModal } from './ConfirmationModal';
import ProductPrice from './ProductPrice';
import { ReplacementOrderModal } from './ReplacementOrderModal';
import { FulfillmentsTable } from './Table';
import { InfoTooltip } from './Tooltip';
import { axios, ENABLE_CLAIMS_FEATURE } from '../api';
import { Pencil } from '../assets/Pencil';
import { InlineSpinner } from '../assets/Spinner';
import { TRACKING_URL } from '../constants';
import { calculatePriceAfterDiscountWithTax } from '../financialUtils';
import { mapFulfillments } from '../orderUtils';
import { isOpsAdmin, isAllModuleAccessUser, isAdmin } from '../pages/constants';
import {
  Customer,
  Fulfillment,
  Order,
  Pet,
  Shipment,
  FulfillmentStatus,
  Item,
  OrderFulfillmentProduct,
} from '../pages/types';
import { FulfillmentHeader } from '../tableHeaders';
import { BOTTOM_TOAST } from '../toastUtils';

interface OrderFulfillmentsProps {
  customer: Customer;
  order: Order;
  refreshCustomer: () => void;
}

const getPetNamesFromFulfillments = (petIds: string[], allPets: Pet[]) => {
  if (!petIds) {
    return '--';
  }
  const petNames = new Set();
  petIds.forEach((pet_id: string) => {
    const name = allPets.find((pet: Pet) => pet.id === pet_id)?.name;
    if (name) petNames.add(name);
  });

  const petNamesConcat = Array.from(petNames).join(', ');
  return petNamesConcat !== '' ? petNamesConcat : '--';
};

const getPetNameForFulfillmentProduct = (product: OrderFulfillmentProduct, allPets: Pet[]) => {
  const petId =
    product.pet_id ??
    (product.fulfillment.pet_ids.length === 1 ? product.fulfillment.pet_ids[0] : null);
  const pet = allPets.find((pet: Pet) => pet.id === petId);
  return pet ? pet.name : '--';
};

const getShipmentTooltip = (shipment: Shipment | undefined) => {
  if (!shipment) return 'No tracking information';

  const statusFields = [
    'shipped',
    'in_transit',
    'out_for_delivery',
    'available_for_pickup',
    'delivered',
    'voided',
    'returned',
    'exception',
  ] as const;
  type StatusField = (typeof statusFields)[number];

  let mostRecentStatus: string | undefined;
  let mostRecentTimestamp: Date | undefined;

  for (const status of statusFields) {
    const timestamp = shipment[status as StatusField];
    if (
      timestamp &&
      (!mostRecentTimestamp || new Date(timestamp) > new Date(mostRecentTimestamp))
    ) {
      mostRecentTimestamp = new Date(timestamp);
      mostRecentStatus = status;
    }
  }

  if (!mostRecentStatus) {
    return 'No tracking information';
  }

  return (
    <div className="text-left">
      Last update from AfterShip <br /> {mostRecentStatus} on{' '}
      {mostRecentTimestamp?.toLocaleString()}
    </div>
  );
};

const getTrackingNumber = (fulfillment: Fulfillment, isMealProduct: boolean) => {
  if (!fulfillment.is_split_fulfillment && (!isMealProduct || fulfillment.shipments.length === 0)) {
    return '--';
  }
  return (
    <ul className="flex flex-col items-center justify-center w-full">
      {fulfillment.shipments.map((shipment: Shipment) => (
        <li key={shipment.tracking_number} className="flex items-center justify-center">
          <a
            onClick={() => window.open(`${TRACKING_URL}/${shipment.tracking_number}`)}
            className="cursor-pointer underline text-anchor mr-2 text-center"
          >
            {shipment.tracking_number}
          </a>
          <InfoTooltip text={getShipmentTooltip(shipment)} />
        </li>
      ))}
    </ul>
  );
};

export const useSplitFulfillment = () => {
  const navigate = useNavigate();

  return (fulfillmentId: number) => {
    const curr = window.location.pathname;
    const fulfillmentPath = `${curr}/${fulfillmentId}/split-fulfillment`;
    navigate(fulfillmentPath);
  };
};

interface ClaimData {
  claim_id: string;
  pet_id: string;
  fulfillment_id: string;
  product_id: string;
}

export const OrderFulfillments = ({ customer, order, refreshCustomer }: OrderFulfillmentsProps) => {
  const [selectedFulfilmentId, setSelectedFulfilmentId] = useState<string | null>(null);
  const [isProcessLoading, setIsProcessLoading] = useState(false);
  const [selectedClaimId, setSelectedClaimId] = useState<string | null>(null);
  const [isDeleteClaimLoading, setIsDeleteClaimLoading] = useState(false);
  const [claims, setClaims] = useState<ClaimData[]>([]);
  const [isFetchingClaims, setIsFetchingClaims] = useState(false);

  const UserHasDeleteAccess = !!(isAdmin() || isOpsAdmin() || isAllModuleAccessUser());

  useEffect(() => {
    const fetchClaimDetails = async () => {
      setIsFetchingClaims(true);
      try {
        const response = await axios.get(`/claims/order/${order.id}`);
        setClaims(response.data);
      } catch (error) {
        toast.error('Failed to fetch claim details', BOTTOM_TOAST);
        console.error('Error fetching claim details:', error);
      } finally {
        setIsFetchingClaims(false);
      }
    };

    if (!isDeleteClaimLoading) {
      fetchClaimDetails();
    }
  }, [order.id, isDeleteClaimLoading]);

  const handleDeleteClaim = () => {
    setIsDeleteClaimLoading(true);
    axios
      .delete(`/claims/${selectedClaimId}`)
      .then(() => {
        refreshCustomer();
        setIsDeleteClaimLoading(false);
        setSelectedClaimId(null);
        toast.success('Claim deleted successfully', BOTTOM_TOAST);
      })
      .catch(() => {
        setIsDeleteClaimLoading(false);
        setSelectedClaimId(null);
        toast.error('Failed to delete claim', BOTTOM_TOAST);
      });
  };

  const canReplaceOrder =
    order.order_type !== 'REPLACEMENT' &&
    ['PARTIALLY_FULFILLED', 'FULFILLED', 'COMPLETE', 'CANCELLED'].some((status) =>
      order.status.includes(status)
    );
  const [showReplacementOrderModal, setShowReplacementOrderModal] = useState(false);
  const mappedFulfillmentProducts = mapFulfillments(order);

  const handleUpdateFulfillment = () => {
    setIsProcessLoading(true);
    axios
      .put(`/orders/mark_fulfillment_delivery_failed/`, { fulfillment_id: selectedFulfilmentId })
      .then(() => {
        refreshCustomer();
        setIsProcessLoading(false);
        setSelectedFulfilmentId(null);
        toast.success('Fulfillment status updated successfully', BOTTOM_TOAST);
      })
      .catch(() => {
        setIsProcessLoading(false);
        setSelectedFulfilmentId(null);
        toast.error('Failed to update fulfillment status', BOTTOM_TOAST);
      });
  };

  const getConfirmationTitle = () => {
    const fulfillment = order.fulfillments.find((fulfillment: Fulfillment) => {
      return fulfillment.id === selectedFulfilmentId;
    });
    return (
      <p>
        Mark {getPetNamesFromFulfillments(fulfillment?.pet_ids ?? [], customer.pets)}'s fulfillment
        as <span className="font-bold">“Delivery Failed”?</span>
      </p>
    );
  };

  const getConfirmationMessage = () => {
    return (
      <p>
        This action will update the fulfillment’s status from{' '}
        <span className="font-bold">“Shipped”</span> to{' '}
        <span className="font-bold">“Delivery Failed.”</span>
      </p>
    );
  };

  const isFulfillmentSplittable = (fulfillment: Fulfillment) => {
    return (
      !fulfillment.is_split_fulfillment &&
      fulfillment.status === FulfillmentStatus.ORDERDESK_SENT &&
      fulfillment.products.length > 1
    );
  };

  const getFulfillmentNumber = (product: OrderFulfillmentProduct) => {
    if (product.fulfillment.is_split_fulfillment || product.isMealProduct) {
      return (
        <div className="flex items-center justify-center space-x-1 w-full">
          <span>{product.fulfillment?.status ? product.fulfillment?.status : ''}</span>
          {product.fulfillment?.status === 'SHIPPED' && (
            <a
              onClick={() => setSelectedFulfilmentId(product.fulfillment.id)}
              className="flex items-center justify-center cursor-pointer underline text-anchor"
            >
              <Pencil className="w-4 h-4 text-black" />
            </a>
          )}
        </div>
      );
    }
    return '--';
  };

  const parseProductItems = (product: OrderFulfillmentProduct) => {
    if (!product || !Array.isArray(product.items) || product.items.length === 0) {
      return '--';
    }

    const itemStrings: string[] = [];

    product.items.forEach((item: Item) => {
      itemStrings.push(`${item.quantity} X ${item.sku}`);
    });

    return itemStrings;
  };

  const seenFulfillmentIds = new Set<string>();

  return (
    <div className="pt-3 pb-6">
      <h2 className="mb-1 flex items-center">
        <b className="inline-block">Fulfillments</b>
        {canReplaceOrder && (
          <div className="text-sm ml-2">
            <a
              onClick={() => setShowReplacementOrderModal(true)}
              className="cursor-pointer underline text-anchor"
            >
              Replace Order
            </a>
          </div>
        )}
      </h2>
      {showReplacementOrderModal && (
        <ReplacementOrderModal
          customer={customer}
          order={order}
          onConfirm={() => setShowReplacementOrderModal(false)}
          onCancel={() => setShowReplacementOrderModal(false)}
        />
      )}
      <div className="bg-white">
        <FulfillmentsTable
          header={FulfillmentHeader}
          data={mappedFulfillmentProducts.map((product: OrderFulfillmentProduct) => {
            // Find matching claim for this product and fulfillment
            const matchingClaim = claims.find(
              (claim) =>
                claim.fulfillment_id === product.fulfillment.id &&
                claim.product_id === product.id &&
                claim.pet_id === product.pet_id
            );

            return {
              'Fulfillment ID': (() => {
                if (seenFulfillmentIds.has(product.fulfillment.id)) {
                  return '--';
                } else {
                  seenFulfillmentIds.add(product.fulfillment.id);
                  return product.fulfillment.id;
                }
              })(),
              Is_Meal_Product: product.isMealProduct,
              Is_Splittable: isFulfillmentSplittable(product.fulfillment),
              'Fulfillment Status': getFulfillmentNumber(product),
              'Shipment Tracking Number(s)': getTrackingNumber(
                product.fulfillment,
                product.isMealProduct
              ),
              Pet: getPetNameForFulfillmentProduct(product, customer.pets),
              'Product Code': (
                <>
                  {product.code}
                  {ENABLE_CLAIMS_FEATURE && matchingClaim && (
                    <span
                      className="ml-1 text-red-600"
                      title="There is a claim associated with this product"
                    >
                      ❗
                    </span>
                  )}
                </>
              ),
              'Product Description': product.description ?? '--',
              'Total Items': `${parseProductItems(product)}`,
              Price: <ProductPrice product={product} />,
              'Price with Tax': `$${calculatePriceAfterDiscountWithTax(product)}`,
              Claims: isFetchingClaims ? (
                <div className="flex justify-center">
                  <InlineSpinner />
                </div>
              ) : (
                <ClaimsColumn
                  claimId={matchingClaim?.claim_id || null}
                  onDeleteClick={setSelectedClaimId}
                  userHasDeleteAccess={UserHasDeleteAccess}
                  product={product}
                  customer={customer}
                />
              ),
            };
          })}
        />
      </div>
      {selectedFulfilmentId && (
        <ConfirmationModal
          isProcessLoading={isProcessLoading}
          title={getConfirmationTitle()}
          message={getConfirmationMessage()}
          confirmLabel="Confirm"
          onConfirm={handleUpdateFulfillment}
          onCancel={() => setSelectedFulfilmentId(null)}
        />
      )}
      {selectedClaimId && (
        <ConfirmationModal
          isProcessLoading={isDeleteClaimLoading}
          title="Are you sure you want to delete this claim?"
          message="Please confirm before proceeding, as claims cannot be restored once deleted."
          confirmLabel="Confirm"
          onConfirm={handleDeleteClaim}
          onCancel={() => setSelectedClaimId(null)}
        />
      )}
    </div>
  );
};
