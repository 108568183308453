import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { ClaimFormProps } from './types';
import { axios } from '../../api/axios';
import { Spinner } from '../../assets/Spinner';
import { ItemSelector } from '../../pages/ItemSelector';
import { ProductRelatedItem } from '../../pages/types';
import { BOTTOM_TOAST, generateErrorMsg } from '../../toastUtils';
import { generateUniqueId } from '../../utils';

export const MispackForm = ({ formData, setFormData, setIsValid, product }: ClaimFormProps) => {
  const [itemSkus, setItemSkus] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const existingItemQuantityMapping = useMemo(
    () => product.items.map((item) => ({ [item.sku]: item.quantity })),
    [product.items]
  );

  const fixedItems = useMemo(
    () => existingItemQuantityMapping.map((item) => Object.keys(item)[0]),
    [existingItemQuantityMapping]
  );

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`items/skus`)
      .then((response) => {
        setItemSkus(response.data.sort());
      })
      .catch((error) => {
        toast.error(generateErrorMsg(error), BOTTOM_TOAST);
      });
    setIsLoading(false);
  }, []);

  const isMispackDifferent = (actuallyReceived: ProductRelatedItem[]): boolean => {
    if (!actuallyReceived) return false;

    // Compare with original product items
    return (
      actuallyReceived.some((actual) => {
        const originalItem = product.items.find((item) => item.sku === actual.sku);
        return !originalItem || originalItem.quantity !== actual.quantity;
      }) ||
      product.items.some(
        (original) => !actuallyReceived.find((actual) => actual.sku === original.sku)
      )
    );
  };

  useEffect(() => {
    const isValidForm =
      formData.mispackHaveInformationOnWhatWasReceived !== undefined &&
      (!formData.mispackHaveInformationOnWhatWasReceived ||
        (formData.mispackActuallyReceived !== undefined &&
          formData.mispackActuallyReceived.every(
            (item) =>
              item.sku && (fixedItems.includes(item.sku) ? item.quantity >= 0 : item.quantity > 0)
          ) &&
          isMispackDifferent(formData.mispackActuallyReceived)));

    setIsValid(isValidForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-2">
        <label className="block text-lg font-semibold text-gray-700 mb-1">
          Do we know what the customer actually received?
        </label>
        <div className="flex flex-col">
          <div className="inline-flex items-center">
            <input
              id="mispack-info-yes"
              type="radio"
              checked={formData.mispackHaveInformationOnWhatWasReceived === true}
              onChange={() =>
                setFormData({
                  ...formData,
                  mispackHaveInformationOnWhatWasReceived: true,
                  mispackShouldHaveReceived: existingItemQuantityMapping,
                  mispackActuallyReceived: product.items.map((item: ProductRelatedItem) => ({
                    sku: item.sku,
                    quantity: item.quantity,
                    id: generateUniqueId(), // Generate unique id for each item (for react list key)
                  })),
                })
              }
              className="h-4 w-4 text-blue-600"
            />
            <label htmlFor="mispack-info-yes" className="ml-2 cursor-pointer">
              Yes
            </label>
          </div>
          <div className="inline-flex items-center">
            <input
              id="mispack-info-no"
              type="radio"
              checked={formData.mispackHaveInformationOnWhatWasReceived === false}
              onChange={() =>
                setFormData({
                  ...formData,
                  mispackHaveInformationOnWhatWasReceived: false,
                  mispackShouldHaveReceived: existingItemQuantityMapping,
                  mispackActuallyReceived: undefined,
                })
              }
              className="h-4 w-4 text-blue-600"
            />
            <label htmlFor="mispack-info-no" className="ml-2 cursor-pointer">
              No
            </label>
          </div>
        </div>
      </div>
      <>
        <label className="block text-lg font-semibold text-gray-700 mb-1">
          Should have received
        </label>
        <div className="flex flex-col space-y-2">
          {product.items.map((item) => (
            <div key={item.id}>
              <div>{`${item.quantity} x ${item.sku} (${item.name})`}</div>
            </div>
          ))}
        </div>
      </>
      {formData.mispackHaveInformationOnWhatWasReceived && (
        <>
          <label className="block text-lg font-semibold text-gray-700 mb-1">
            Actually received
          </label>
          <ItemSelector
            items={itemSkus}
            selectedItems={formData.mispackActuallyReceived || []}
            setSelectedItems={(items) =>
              setFormData({
                ...formData,
                mispackActuallyReceived: items as ProductRelatedItem[],
              })
            }
            fixedItems={fixedItems}
          />
        </>
      )}
    </div>
  );
};
