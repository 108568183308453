import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { axios } from '../api';
import { parseNumber } from '../financialUtils';
import {
  useOrderSummaryProductsForPayload,
  useOrderSummaryProductsForView,
} from '../orderSummaryUtils';
import { Customer, Option, OrderProduct, OrderProductType, PetPlan } from '../pages/types';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

type getOrderSummaryProps = {
  customer: Customer;
  selectedProducts: Option[];
  selectedDog: any;
  discount_codes?: string[];
  isReplacement?: boolean;
  existingOrderProducts?: OrderProduct[];
  dogs?: Option[];
  order_id?: string;
};

export type OneTimeOrderProduct = {
  product_code: string;
  quantity: number;
  pet_plan?: PetPlan;
  type: OrderProductType;
  price?: string;
};

export const useGetOrderSummary = ({
  customer,
  selectedProducts,
  selectedDog,
  discount_codes = [],
  isReplacement = false,
  existingOrderProducts = [],
  dogs = [],
  order_id = undefined,
}: getOrderSummaryProps) => {
  const petPlan = useMemo(
    () => customer.petplans.find((petPlan: any) => petPlan.pet === selectedDog.value),
    [customer.petplans, selectedDog.value]
  );

  const [summary, setSummary] = useState<any>();
  const [triggerRequest, setTriggerRequest] = useState(0);
  const [error, setError] = useState(null);

  const reCallOrderSummary = () => {
    setTriggerRequest((prev) => prev + 1);
  };

  type CreateOrdersPayload = {
    account: string;
    order_products: {
      product_code: string;
      quantity: number;
      pet_plan: string;
      price?: string;
      type: OrderProductType;
    }[];
  };

  const defaultOrderProducts = petPlan
    ? selectedProducts.map((product: Option) => {
        return {
          product_code: product.label,
          quantity: 1,
          pet_plan: petPlan.id,
          type: isReplacement ? OrderProductType.REPLACEMENT : OrderProductType.ONETIME,
        };
      })
    : [];
  const [orderProducts, setOrderProducts] =
    useState<CreateOrdersPayload['order_products']>(defaultOrderProducts);

  const formattedExistingProductsForPayload =
    useOrderSummaryProductsForPayload(existingOrderProducts);
  const formattedExistingProductsForView = useOrderSummaryProductsForView(
    selectedDog,
    dogs,
    existingOrderProducts
  );

  const getSummary = (customer: Customer, orderProducts: CreateOrdersPayload['order_products']) => {
    axios
      .post(`orders?dry_run=true`, {
        account: customer.id,
        order_products: [...orderProducts, ...formattedExistingProductsForPayload],
        order_type: 'ONETIME',
        discount_codes,
        order_id,
      })
      .then((response) => {
        setSummary(response.data);
        const { order_products } = response.data;
        // Adding price for each one-time product from summary response
        setOrderProducts((prev) =>
          prev.map((product) => {
            const productWithPrice = order_products.filter(
              (op: any) => op.product_code === product.product_code
            )[0];
            return {
              ...product,
              price: (
                parseNumber(productWithPrice.unit_price) * parseNumber(productWithPrice.quantity)
              ).toFixed(2),
            };
          })
        );
        setError(null);
      })
      .catch((e) => {
        setError(e?.response?.data);
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  useEffect(() => {
    getSummary(customer, orderProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customer.id,
    orderProducts.length,
    selectedDog.value,
    discount_codes.length,
    triggerRequest,
    customer,
  ]);

  return {
    summary: {
      ...summary,
      summaryProducts: {
        products: formattedExistingProductsForView,
        existingOrderProducts: formattedExistingProductsForPayload,
        doesExist: existingOrderProducts.length > 0,
      },
    },
    orderProducts,
    discount_codes: summary?.discount_codes || [],
    setOrderProducts,
    reCallOrderSummary,
    error,
  } as {
    summary: any;
    discount_codes: string[];
    orderProducts: CreateOrdersPayload['order_products'];
    setOrderProducts: React.Dispatch<React.SetStateAction<CreateOrdersPayload['order_products']>>;
    reCallOrderSummary: () => void;
    error: any;
  };
};
