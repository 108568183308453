import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { axios } from '../api';
import { ClaimFormData, ProductQualityIssueBackend } from '../component/Claim/types';
import { BOTTOM_TOAST } from '../toastUtils';
import { generateUniqueId } from '../utils';

interface UseFetchClaimProps {
  claimId?: string;
  productCode?: string;
  initialFormData: ClaimFormData;
  onError?: () => void;
  skipFetch?: boolean;
}

export const useFetchClaim = ({
  claimId,
  productCode,
  initialFormData,
  onError,
  skipFetch = false,
}: UseFetchClaimProps) => {
  const [formData, setFormData] = useState<ClaimFormData>(initialFormData);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setFormData(initialFormData);
  }, [initialFormData]);

  useEffect(() => {
    if (skipFetch || !productCode) return;

    if (claimId) {
      setIsLoading(true);
      axios
        .get(`/claims/${claimId}`)
        .then((response) => {
          setFormData({
            ...initialFormData,
            claimTypes: response.data.claim_types,
            gladlyConversationUrl: response.data.gladly_conversation_url,
            photosReceived: response.data.photos_received,
            resolutionTypes: response.data.resolution_types,
            adverseReactionVetVisitRequired: response.data.adverse_reaction_vet_visit_required,
            adverseReactionVetBillRequested: response.data.adverse_reaction_vet_bill_requested,
            adverseReactionVetBillCost: response.data.adverse_reaction_vet_bill_cost,
            adverseReactionSymptoms: response.data.adverse_reaction_symptoms,
            adverseReactionAdditionalNotes: response.data.adverse_reaction_additional_notes,
            adverseReactionLotCodes: response.data.adverse_reaction_lot_codes,
            feedingGuideIssueType: response.data.feeding_guide_issue_type,
            mispackHaveInformationOnWhatWasReceived:
              response.data.mispack_have_information_on_what_was_received,
            mispackShouldHaveReceived: response.data.mispack_should_have_received,
            mispackActuallyReceived: response.data.mispack_actually_received?.map(
              (item: Record<string, number>) => ({
                sku: Object.keys(item)[0],
                quantity: item[Object.keys(item)[0]],
                id: generateUniqueId(),
              })
            ),
            productQualityIssues: response.data.product_quality_issues?.map(
              (issue: ProductQualityIssueBackend) => ({
                id: generateUniqueId(),
                issueType: issue.issue_type,
                subIssue: issue.sub_issue,
                notes: issue.notes,
                lotCodes: issue.lot_codes,
              })
            ),
            roofUnkibbleUsedCorrectScoop: response.data.roof_unkibble_used_correct_scoop,
            roofUnkibbleFedCorrectNumberOfScoops:
              response.data.roof_unkibble_fed_correct_number_of_scoops,
            roofUnkibbleLeveledOffScoop: response.data.roof_unkibble_leveled_off_scoop,
            roofFreshFedCorrectNumberOfPacks: response.data.roof_fresh_fed_correct_number_of_packs,
            roofLotCodes: response.data.roof_lot_codes,
            warmBoxFoodInsideSilverBag: response.data.warm_box_food_inside_silver_bag,
            warmBoxSilverBagSealed: response.data.warm_box_silver_bag_sealed,
            warmBoxGelPackOrDryIcePresent: response.data.warm_box_gel_pack_or_dry_ice_present,
          });
          setIsError(false);
        })
        .catch(() => {
          toast.error('No related product data found', BOTTOM_TOAST);
          setIsError(true);
          if (onError) onError();
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setIsError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimId, productCode, skipFetch]);

  return { formData, setFormData, isLoading, isError };
};
