import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { Customer, Order } from './types';
import { axios } from '../api';
import { Spinner } from '../assets/Spinner';
import { Button } from '../component/Button';
import { CheckboxTable } from '../component/CheckboxTable';
import { ConfirmationModal } from '../component/ConfirmationModal';
import { NotFound } from '../component/NotFound';
import { useCustomParams } from '../hooks/useCustomParams';
import { generateFullName } from '../nameUtils';
import { getOrder } from '../orderUtils';
import { SplitFulfillmentHeaders } from '../tableHeaders';
import { BOTTOM_TOAST } from '../toastUtils';

export const SplitFulfillment = () => {
  const { customerId, orderId, fulfillmentId } = useCustomParams();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [isProcessLoading, setIsProcessLoading] = useState(false);

  useEffect(() => {
    getCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = (index: number) => {
    if (selectedRows.includes(index)) {
      setSelectedRows((prevSelected) => prevSelected.filter((rowIndex) => rowIndex !== index));
    } else {
      setSelectedRows((prevSelected) => [...prevSelected, index]);
    }
  };
  const getCustomer = () => {
    axios
      .get(`accounts/${customerId}`)
      .then((response) => {
        setCustomer(response.data);
      })
      .catch(() => <NotFound />);
  };

  const findFulfillmentByProductId = (order: Order, productId: any) => {
    // Loop through each fulfillment in the order
    for (const fulfillment of order.fulfillments) {
      // Check if any product in this fulfillment has the given product ID
      if (fulfillment.products.some((product) => product.id === productId)) {
        return fulfillment.id; // Return the fulfillment that contains the product
      }
    }
    // Return null if the product ID is not found in any fulfillment
    return null;
  };

  const mapOrderProducts = (order: any) => {
    const filteredProducts = order.products.filter((product: any) => {
      return findFulfillmentByProductId(order, product.product) === fulfillmentId;
    });

    return filteredProducts.map((product: any, i: number) => {
      return {
        'Fulfillment ID': findFulfillmentByProductId(order, product.product),
        'Product Description': product.description ?? '--',
        'Pet Name': product.pet_plan?.pet_name,
        Quantity: product.quantity,
        'Product Code': product.code,
      };
    });
  };

  const order = useMemo(() => {
    return getOrder(customer?.orders, orderId);
  }, [customer?.orders, orderId]);

  const handleSplitFulfillment = () => {
    setIsProcessLoading(true);

    const mappedFulfillments = mapOrderProducts(order);
    const productCodesToSplit = selectedRows.map((index) => {
      const orderProduct = mappedFulfillments[index];
      return orderProduct['Product Code'];
    });

    axios
      .post(`fulfillments/${fulfillmentId}/split`, {
        product_codes_to_split: productCodesToSplit,
      })
      .then(() => {
        setIsProcessLoading(false);
        setShowModal(false);

        // Save toast message in localStorage
        localStorage.setItem(
          'toastMessage',
          JSON.stringify({
            message: fulfillmentId,
            type: 'success', // Optional: Toast type
          })
        );

        // Navigate to the new page
        const urlParts = window.location.href.split('/');
        urlParts.pop();
        urlParts.pop();
        location.href = urlParts.join('/');
      })
      .catch(() => {
        toast.error(
          'Error splitting fulfillment ' +
            fulfillmentId +
            '. Please ensure fulfillment is not already split off from another, and that more than one product exists in this fulfillment.',
          BOTTOM_TOAST
        );
        setIsProcessLoading(false);
        setShowModal(false);
      });
  };

  if (!customer) return <Spinner />;

  return (
    <div className="h-[calc(100vh)] bg-slate50 p-8">
      <div className="pt-3 pb-3">
        <h2 className="mb-1 flex items-center">Split Fulfillment</h2>
        <b>Customer:</b>{' '}
        {`${generateFullName(customer.first_name, customer.last_name)} (${customer.email})`} <br />
        <b>Order:</b> {order.id}
      </div>
      <div className="bg-white">
        <b className="mb-4 flex block">
          Select the product(s) you would like to split into a separate fulfillment:
        </b>{' '}
        <CheckboxTable
          header={SplitFulfillmentHeaders}
          data={mapOrderProducts(order)}
          selectedRows={selectedRows}
          onCheckboxChange={handleCheckboxChange}
        />
      </div>

      <div className="flex items-center justify-center pt-5 pb-4">
        <Button
          label="Split Fulfillment"
          onClick={() => {
            setShowModal(true);
          }}
          isDisabled={selectedRows.length === 0}
          variant={selectedRows.length > 0 ? 'primary' : 'secondary'}
        />
        <br />
      </div>
      {showModal && (
        <ConfirmationModal
          isProcessLoading={isProcessLoading}
          title="Are you sure you want to proceed with splitting this fulfillment?"
          message="A new fulfillment will be created in our database with the selected products. Please make sure to make this same split in Order Desk afterwards."
          confirmLabel="Confirm"
          onConfirm={handleSplitFulfillment}
          onCancel={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
