import { useMemo } from 'react';

import { Option, OrderProduct } from './pages/types';

export const useOrderSummaryProductsForView = (
  selectedDog: Option,
  dogs: Option[],
  existedProducts: OrderProduct[]
) =>
  useMemo(() => {
    // Grouped by dogName
    const orderSummary: any = {
      [selectedDog.label]: {},
    };

    for (const pet of dogs.filter((el) => el.label !== selectedDog.label)) {
      orderSummary[pet.label] = {};
    }

    for (const product of existedProducts) {
      const { product_type, pet_plan } = product;
      const petName = pet_plan?.pet_name;
      if (!petName) continue;
      const summaryProduct = {
        product_code: product.code,
        unit_price: product.unit_price,
        discount_amount: product.discount_amount,
      };

      if (orderSummary[petName][product_type]) {
        orderSummary[petName][product_type].push(summaryProduct);
      } else {
        orderSummary[petName][product_type] = [summaryProduct];
      }
    }

    return orderSummary;
  }, [dogs, existedProducts, selectedDog.label]);

export const useOrderSummaryProductsForPayload = (existedProducts: any[]) =>
  useMemo(() => {
    const forRequest = [];
    for (const product of existedProducts) {
      const requestProduct = {
        product_code: product.code,
        quantity: product.quantity,
        pet_plan: product.pet_plan.id,
        type: product.type,
      };
      forRequest.push(requestProduct);
    }

    return forRequest;
  }, [existedProducts]);
