import * as React from 'react';
import { useEffect } from 'react';

import {
  ResolutionStatusOptions,
  WarmBoxGelPackOrDryIcePresentOptions,
  WarmBoxSilverBagSealedStatusOptions,
} from './constants';
import { ClaimFormProps } from './types';

export const WarmBoxForm = ({ formData, setFormData, setIsValid, product }: ClaimFormProps) => {
  useEffect(() => {
    const isValidForm =
      formData.warmBoxFoodInsideSilverBag !== undefined &&
      formData.warmBoxSilverBagSealed !== undefined &&
      formData.warmBoxGelPackOrDryIcePresent !== undefined;

    setIsValid(isValidForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-2">
        <label className="block text-lg font-semibold text-gray-700 mb-1">
          Was the food inside a silver bag?
        </label>
        <div className="space-y-2">
          {ResolutionStatusOptions.map((option) => (
            <label key={option.value} className="flex items-center space-x-2">
              <input
                type="radio"
                name="warm_box_food_inside_silver_bag"
                value={option.value}
                checked={formData.warmBoxFoodInsideSilverBag === option.value}
                onChange={() =>
                  setFormData({
                    ...formData,
                    warmBoxFoodInsideSilverBag: option.value,
                  })
                }
                className="h-4 w-4 text-blue-600"
              />
              <span>{option.label}</span>
            </label>
          ))}
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <label className="block text-lg font-semibold text-gray-700 mb-1">
          Was the silver bag well-sealed?
        </label>
        <div className="space-y-2">
          {WarmBoxSilverBagSealedStatusOptions.map((option) => (
            <label key={option.value} className="flex items-center space-x-2">
              <input
                type="radio"
                name="warm_box_silver_bag_sealed"
                value={option.value}
                checked={formData.warmBoxSilverBagSealed === option.value}
                onChange={() =>
                  setFormData({
                    ...formData,
                    warmBoxSilverBagSealed: option.value,
                  })
                }
                className="h-4 w-4 text-blue-600"
              />
              <span>{option.label}</span>
            </label>
          ))}
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <label className="block text-lg font-semibold text-gray-700 mb-1">
          Was there gel pack or dry ice present?
        </label>
        <div className="space-y-2">
          {WarmBoxGelPackOrDryIcePresentOptions.map((option) => (
            <label key={option.value} className="flex items-center space-x-2">
              <input
                type="radio"
                name="warm_box_gel_pack_or_dry_ice_present"
                value={option.value}
                checked={formData.warmBoxGelPackOrDryIcePresent === option.value}
                onChange={() =>
                  setFormData({
                    ...formData,
                    warmBoxGelPackOrDryIcePresent: option.value,
                  })
                }
                className="h-4 w-4 text-blue-600"
              />
              <span>{option.label}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};
