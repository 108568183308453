import * as React from 'react';
import { useEffect, useState } from 'react';

import { ItemLotCodeInput } from './ItemLotCodeInput';
import { getIssueOptions, getSubIssueOptions } from './productQualityOptions';
import { ClaimFormProps, ProductQualityIssue } from './types';
import { Dropdown } from '../../component/Dropdown';
import { ProductType } from '../../pages/types';
import { generateUniqueId } from '../../utils';

export const ProductQualityForm = ({
  formData,
  setFormData,
  setIsValid,
  product,
}: ClaimFormProps) => {
  const [errors, setErrors] = useState<Record<string, string>>({});

  const addNewIssue = () => {
    setFormData({
      ...formData,
      productQualityIssues: [
        ...(formData.productQualityIssues || []),
        {
          id: generateUniqueId(),
          issueType: undefined,
          subIssue: undefined,
          notes: undefined,
          lotCodes: {},
        } as ProductQualityIssue,
      ],
    });
  };

  const removeIssue = (id: string) => {
    // Don't remove if it's the only issue
    if (!formData.productQualityIssues || formData.productQualityIssues.length <= 1) return;

    const issueIndex = formData.productQualityIssues.findIndex((issue) => issue.id === id);
    if (issueIndex === -1) {
      return;
    }

    setFormData({
      ...formData,
      productQualityIssues: formData.productQualityIssues.filter((issue) => issue.id !== id),
    });
  };

  // Initialize with one empty issue if none exist
  useEffect(() => {
    if (!formData.productQualityIssues || formData.productQualityIssues.length === 0) {
      addNewIssue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Validate the form
  useEffect(() => {
    const isValidForm = !!formData.productQualityIssues?.every((issue) => {
      if (Object.values(errors).some((error) => error !== '')) return false;
      if (!issue.issueType) return false;
      if (!['UNKNOWN', 'OTHER'].includes(issue.issueType) && !issue.subIssue) return false;

      // Check if notes are required and provided
      const subIssueOptions = getSubIssueOptions(
        issue.issueType,
        product.type as ProductType,
        product.is_frozen
      );
      const selectedSubIssue = subIssueOptions.find((option) => option.value === issue.subIssue);

      if ((selectedSubIssue?.requiresNotes || issue.issueType === 'OTHER') && !issue.notes)
        return false;

      if (issue.lotCodes && Object.values(issue.lotCodes).every((lotCode) => lotCode === undefined))
        return false;

      return true;
    });

    setIsValid(isValidForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.productQualityIssues]);

  const handleIssueTypeChange = (id: string, value: string) => {
    const updatedIssues = (formData.productQualityIssues || []).map((issue: ProductQualityIssue) =>
      issue.id === id
        ? {
            ...issue,
            issueType: value,
            subIssue: undefined,
            notes: undefined,
          }
        : issue
    );

    setFormData({
      ...formData,
      productQualityIssues: updatedIssues,
    });
  };

  const handleSubIssueChange = (id: string, value: string) => {
    const updatedIssues = (formData.productQualityIssues || []).map((issue) =>
      issue.id === id ? { ...issue, subIssue: value, notes: undefined } : issue
    );

    setFormData({
      ...formData,
      productQualityIssues: updatedIssues,
    });
  };

  const handleNotesChange = (id: string, value: string) => {
    const updatedIssues = (formData.productQualityIssues || []).map((issue) =>
      issue.id === id ? { ...issue, notes: value } : issue
    );

    setFormData({
      ...formData,
      productQualityIssues: updatedIssues,
    });
  };

  const handleLotCodeChange = (id: string, sku: string, lotCode: string, toDelete?: boolean) => {
    const updatedIssues = (formData.productQualityIssues || []).map((issue) =>
      issue.id === id
        ? {
            ...issue,
            lotCodes: issue.lotCodes
              ? {
                  ...issue.lotCodes,
                  [sku]: toDelete ? undefined : lotCode,
                }
              : { [sku]: toDelete ? undefined : lotCode },
          }
        : issue
    );

    setFormData({
      ...formData,
      productQualityIssues: updatedIssues as ProductQualityIssue[],
    });
  };

  return (
    <div className="flex flex-col space-y-4">
      {formData.productQualityIssues?.map((issue, index) => (
        <div
          key={issue.id}
          className="bg-white rounded-lg shadow-md p-4 relative border flex flex-col space-y-4"
        >
          <ItemLotCodeInput
            items={product.items}
            lotCodes={issue.lotCodes}
            onLotCodeChange={(sku, lotCode, toDelete) =>
              handleLotCodeChange(issue.id, sku, lotCode, toDelete)
            }
            isUnkibbleMeal={product.isMealProduct && !product.is_frozen}
            errors={errors}
            setErrors={setErrors}
            onDelete={index > 0 ? () => removeIssue(issue.id) : undefined}
          />

          <div className="flex flex-col space-y-2">
            <label className="block text-lg font-semibold text-gray-700 mb-1">
              Product quality issue
            </label>
            <Dropdown
              options={getIssueOptions(product.type as ProductType, product.is_frozen).map(
                (option) => ({
                  value: option.value,
                  label: option.label,
                })
              )}
              selectedOption={
                issue.issueType
                  ? {
                      value: issue.issueType,
                      label:
                        getIssueOptions(product.type as ProductType, product.is_frozen).find(
                          (opt) => opt.value === issue.issueType
                        )?.label || issue.issueType,
                    }
                  : undefined
              }
              defaultText="Select an issue type"
              onSelect={(option) => handleIssueTypeChange(issue.id, option.value)}
              noDefaultSelection
            />
          </div>

          {/* Sub-Issue Selection - Dropdown (only if issue type is selected and not UNKNOWN) */}
          {issue.issueType && !['UNKNOWN', 'OTHER'].includes(issue.issueType) && (
            <div className="flex flex-col space-y-2">
              <label className="block text-lg font-semibold text-gray-700 mb-1">
                Product quality sub-issue
              </label>
              <Dropdown
                key={issue.id + issue.issueType}
                options={getSubIssueOptions(
                  issue.issueType,
                  product.type as ProductType,
                  product.is_frozen
                ).map((option) => ({
                  value: option.value,
                  label: option.label,
                }))}
                selectedOption={
                  issue.subIssue
                    ? {
                        value: issue.subIssue,
                        label:
                          getSubIssueOptions(
                            issue.issueType,
                            product.type as ProductType,
                            product.is_frozen
                          ).find((opt) => opt.value === issue.subIssue)?.label || issue.subIssue,
                      }
                    : undefined
                }
                defaultText="Select a sub-issue type"
                onSelect={(option) => handleSubIssueChange(issue.id, option.value)}
                noDefaultSelection
              />
            </div>
          )}

          {((issue.issueType &&
            issue.subIssue &&
            getSubIssueOptions(
              issue.issueType,
              product.type as ProductType,
              product.is_frozen
            ).find((option) => option.value === issue.subIssue)?.requiresNotes) ||
            ([ProductType.SCOOP, ProductType.MATERIAL, ProductType.MERCH].includes(product.type) &&
              issue.issueType === 'OTHER')) && (
            <div className="flex flex-col space-y-2">
              <label className="block text-lg font-semibold text-gray-700 mb-1">
                Additional Details
              </label>
              <textarea
                value={issue.notes || ''}
                onChange={(e) => handleNotesChange(issue.id, e.target.value)}
                className="w-full p-2 border rounded min-h-[100px]"
                placeholder="Add more details on the issue"
              />
            </div>
          )}
        </div>
      ))}

      <div className="bg-white rounded-lg shadow-md p-4 relative border flex space-x-2 font-bold">
        <div className="space-x-0.5">+</div>
        <a
          href="#"
          className="underline"
          onClick={(e) => {
            e.preventDefault();
            addNewIssue();
          }}
        >
          Add another product quality issue
        </a>
      </div>
    </div>
  );
};
