import * as React from 'react';
import { useEffect, useState } from 'react';

import { ItemLotCodeInput } from './ItemLotCodeInput';
import { ResolutionStatusOptions } from './constants';
import { ClaimFormProps } from './types';

export const RoofForm = ({ formData, setFormData, setIsValid, product }: ClaimFormProps) => {
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    if (formData.roofLotCodes) {
      return;
    }
    setFormData({
      ...formData,
      roofLotCodes: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isValidForm =
      (!product.is_frozen
        ? formData.roofUnkibbleUsedCorrectScoop !== undefined &&
          formData.roofUnkibbleFedCorrectNumberOfScoops !== undefined &&
          formData.roofUnkibbleLeveledOffScoop !== undefined
        : formData.roofFreshFedCorrectNumberOfPacks !== undefined) &&
      formData.roofLotCodes !== undefined &&
      Object.values(formData.roofLotCodes).some((lotCode) => lotCode !== undefined) &&
      !Object.values(errors).some((error) => error !== '');

    setIsValid(isValidForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleLotCodeChange = (sku: string, lotCode: string, to_delete?: boolean) => {
    if (to_delete) {
      const newLotCodes = { ...formData.roofLotCodes };
      delete newLotCodes[sku];
      setFormData({
        ...formData,
        roofLotCodes: newLotCodes,
      });
    } else {
      setFormData({
        ...formData,
        roofLotCodes: { ...formData.roofLotCodes, [sku]: lotCode },
      });
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <ItemLotCodeInput
        items={product.items}
        lotCodes={formData.roofLotCodes}
        onLotCodeChange={handleLotCodeChange}
        isUnkibbleMeal={product.isMealProduct && !product.is_frozen}
        errors={errors}
        setErrors={setErrors}
      />
      {!product.is_frozen ? (
        <>
          <div className="flex flex-col space-y-2">
            <label className="block text-lg font-semibold text-gray-700 mb-1">
              Did the customer use the {product.plan_size?.scoop_calories ?? 'recommended'} scoop
              that matches their recipe?
            </label>
            <div className="space-y-2">
              {ResolutionStatusOptions.map((option) => (
                <div key={option.value} className="flex items-center space-x-2">
                  <input
                    id={`roof-scoop-${option.value}`}
                    type="radio"
                    name="roof_unkibble_used_correct_scoop"
                    value={option.value}
                    checked={formData.roofUnkibbleUsedCorrectScoop === option.value}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        roofUnkibbleUsedCorrectScoop: option.value,
                      })
                    }
                    className="h-4 w-4 text-blue-600"
                  />
                  <label htmlFor={`roof-scoop-${option.value}`} className="cursor-pointer">
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <label className="block text-lg font-semibold text-gray-700 mb-1">
              Did the customer feed {product.scoops_per_day ?? 'recommended'} scoops per day?
            </label>
            <div className="space-y-2">
              {ResolutionStatusOptions.map((option) => (
                <div key={option.value} className="flex items-center space-x-2">
                  <input
                    id={`roof-scoops-per-day-${option.value}`}
                    type="radio"
                    name="roof_unkibble_fed_correct_number_of_scoops"
                    value={option.value}
                    checked={formData.roofUnkibbleFedCorrectNumberOfScoops === option.value}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        roofUnkibbleFedCorrectNumberOfScoops: option.value,
                      })
                    }
                    className="h-4 w-4 text-blue-600"
                  />
                  <label htmlFor={`roof-scoops-per-day-${option.value}`} className="cursor-pointer">
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <label className="block text-lg font-semibold text-gray-700 mb-1">
              Did the customer level off the scoop (no heaping portions)?
            </label>
            <div className="space-y-2">
              {ResolutionStatusOptions.map((option) => (
                <div key={option.value} className="flex items-center space-x-2">
                  <input
                    id={`roof-level-scoop-${option.value}`}
                    type="radio"
                    name="roof_unkibble_leveled_off_scoop"
                    value={option.value}
                    checked={formData.roofUnkibbleLeveledOffScoop === option.value}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        roofUnkibbleLeveledOffScoop: option.value,
                      })
                    }
                    className="h-4 w-4 text-blue-600"
                  />
                  <label htmlFor={`roof-level-scoop-${option.value}`} className="cursor-pointer">
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col space-y-2">
          <label className="block text-lg font-semibold text-gray-700 mb-1">
            Did the customer feed {product.packs_per_day ?? 'recommended'} x{' '}
            {product.items[0].sku.split('-').pop()} pack per day?
          </label>
          <div className="space-y-2">
            {ResolutionStatusOptions.map((option) => (
              <div key={option.value} className="flex items-center space-x-2">
                <input
                  id={`roof-packs-per-day-${option.value}`}
                  type="radio"
                  name="roof_fresh_fed_correct_number_of_packs"
                  value={option.value}
                  checked={formData.roofFreshFedCorrectNumberOfPacks === option.value}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      roofFreshFedCorrectNumberOfPacks: option.value,
                    })
                  }
                  className="h-4 w-4 text-blue-600"
                />
                <label htmlFor={`roof-packs-per-day-${option.value}`} className="cursor-pointer">
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
