import * as React from 'react';
import { useEffect, useState } from 'react';

import { ClaimTypeSelectDropdown } from './ClaimTypeSelectDropdown';
import { ResolutionTypeOptions } from './constants';
import { ClaimFormProps } from './types';
import { ENV } from '../../constants';
import { MultiSelectDropdown } from '../MultiSelectDropdown';
import { TextInput } from '../TextInput';

export const ClaimBasicInfo = ({ formData, setFormData, setIsValid, product }: ClaimFormProps) => {
  const [errors, setErrors] = useState({
    gladly_conversation_url: '',
    resolution_types: '',
  });

  const validateForm = () => {
    const newErrors = {
      gladly_conversation_url: '',
      resolution_types: '',
    };

    // Validate Gladly URL
    if (
      formData.gladlyConversationUrl &&
      !formData.gladlyConversationUrl.includes(
        ENV === 'PROD'
          ? 'https://spotandtango.us-1.gladly.com/'
          : 'https://spotandtango.us-uat.gladly.qa/'
      )
    ) {
      newErrors.gladly_conversation_url = 'Must be a valid Gladly conversation URL';
    }

    // Validate resolution types
    if (formData.resolutionTypes.length > 1 && formData.resolutionTypes.includes('NO_RESOLUTION')) {
      newErrors.resolution_types =
        'Cannot select multiple resolution types if "No Resolution" is selected';
    }

    setErrors(newErrors);

    // Form is valid if there are no error messages
    const isValidForm =
      formData.claimTypes.length > 0 &&
      formData.gladlyConversationUrl.length > 0 &&
      formData.photosReceived !== undefined &&
      formData.resolutionTypes.length > 0 &&
      !Object.values(newErrors).some((error) => error !== '');
    setIsValid(isValidForm);
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <div className="flex flex-col space-y-4">
      <div>
        <label className="block text-lg font-semibold text-gray-700 mb-1">Claim Types</label>
        <ClaimTypeSelectDropdown
          selectedOptions={formData.claimTypes}
          formData={formData}
          setFormData={setFormData}
          isMealProduct={product.isMealProduct}
          isFrozen={product.is_frozen}
        />
      </div>

      <div>
        <label className="block text-lg font-semibold text-gray-700 mb-1">
          Gladly Conversation URL
        </label>
        <TextInput
          value={formData.gladlyConversationUrl}
          setTextChange={(url) => setFormData({ ...formData, gladlyConversationUrl: url })}
          placeholder="Enter link to Gladly conversation"
          error={errors.gladly_conversation_url}
        />
      </div>

      <div>
        <label className="block text-lg font-semibold text-gray-700 mb-1">Photos Received</label>
        <div className="flex flex-col">
          <div className="inline-flex items-center">
            <input
              id="photos-received-yes"
              type="radio"
              checked={formData.photosReceived === true}
              onChange={() => setFormData({ ...formData, photosReceived: true })}
              className="h-4 w-4 text-blue-600"
            />
            <label htmlFor="photos-received-yes" className="ml-2 cursor-pointer">
              Yes
            </label>
          </div>
          <div className="inline-flex items-center">
            <input
              id="photos-received-no"
              type="radio"
              checked={formData.photosReceived === false}
              onChange={() => setFormData({ ...formData, photosReceived: false })}
              className="h-4 w-4 text-blue-600"
            />
            <label htmlFor="photos-received-no" className="ml-2 cursor-pointer">
              No
            </label>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-lg font-semibold text-gray-700 mb-1">Resolution Types</label>
        <MultiSelectDropdown
          options={ResolutionTypeOptions}
          selectedOptions={formData.resolutionTypes.map((resolution) => ({
            label: resolution,
            value: resolution,
          }))}
          setSelectedOptions={(selectedResolutionTypes) =>
            setFormData({
              ...formData,
              resolutionTypes: selectedResolutionTypes.map((resType) => resType.value),
            })
          }
          placeholder="Select resolution types"
          error={errors.resolution_types}
          hideSearch
        />
      </div>
    </div>
  );
};
