import * as React from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';

import { ClaimForm } from './component/Claim/ClaimForm';
import { NotFound } from './component/NotFound';
import { ProtectedRoute } from './component/ProtectedRoute';
import { Unauthorized } from './component/Unauthorized';
import { UnprotectedRoute } from './component/UnprotectedRoute';
import { AuthProvider, useAuth } from './context/AuthContext';
import { CreateDiscount } from './pages/CreateDiscount';
import { CreateItem } from './pages/CreateItem';
import { CreatePromotion } from './pages/CreatePromotion';
import { CreateUser } from './pages/CreateUser';
import { CustomerChangelog } from './pages/CustomerChangelog';
import { CustomerLookup } from './pages/CustomerLookup';
import { CustomerProfile } from './pages/CustomerProfile';
import { CustomerRefund } from './pages/CustomerRefund';
import { DiscountLookup } from './pages/DiscountLookup';
import { Discounts } from './pages/Discounts';
import { ForgotPassword } from './pages/ForgotPassword';
import { Home } from './pages/Home';
import { Items } from './pages/Items';
import { Login } from './pages/Login';
import ManageData from './pages/ManageData';
import { OrderDetailFull } from './pages/OrderDetailFull';
import { ProductItems } from './pages/ProductItems';
import ProductItemsMappingForm from './pages/ProductItemsMappingForm';
import { Promotions } from './pages/Promotions';
import { ResetPassword } from './pages/ResetPassword';
import { SplitFulfillment } from './pages/SplitFulfillment';
import { Category, SystemChangelog } from './pages/SystemChangelog';
import { UpdateDiscountContainer } from './pages/UpdateDiscountContainer';
import { UserManagement } from './pages/UserManagement';
import { ViewPromotion } from './pages/ViewPromotion';
import { isAdmin, isOpsAdmin, isTeamMember, isAllModuleAccessUser } from './pages/constants';

const Layout = (
  <div>
    <Outlet />
  </div>
);

export const BarkRouter = () => {
  const { isAuthenticated } = useAuth();

  const routes = createBrowserRouter([
    {
      path: '*',
      element: <NotFound />,
    },
    {
      path: '/login',
      element: (
        <UnprotectedRoute>
          <Login />
        </UnprotectedRoute>
      ),
    },
    {
      path: '/resetpassword',
      element: <ForgotPassword />,
    },
    {
      path: '/reset-password/:tokenId/:tokenKey',
      element: <ResetPassword />,
    },
    {
      path: '/',
      element: <ProtectedRoute />,
      handle: {
        title: 'Home',
      },
      children: [
        {
          path: '/',
          element:
            isAuthenticated &&
            (isAdmin() || isTeamMember() || isOpsAdmin() || isAllModuleAccessUser()) ? (
              <Home />
            ) : (
              <Unauthorized />
            ),
        },
        {
          path: '/user-management',
          element:
            isAuthenticated && (isAdmin() || isOpsAdmin() || isAllModuleAccessUser()) ? (
              Layout
            ) : (
              <Unauthorized />
            ),
          handle: {
            title: 'Manage Users',
          },
          children: [
            {
              path: '/user-management',
              element: <UserManagement />,
            },
            {
              path: '/user-management/create-user',
              element: <CreateUser />,
              handle: {
                title: 'Create user',
              },
            },
          ],
        },
        {
          path: '/create-user',
          element: <CreateUser />,
          handle: {
            title: 'Create user',
          },
        },
        {
          path: '/customer-lookup',
          element: Layout,
          handle: {
            title: 'Customer Lookup',
          },
          children: [
            {
              path: '/customer-lookup',
              element: <CustomerLookup />,
            },
            {
              path: '/customer-lookup/:customerId',
              element: Layout,
              handle: {
                title: 'customerId',
              },
              children: [
                {
                  path: '/customer-lookup/:customerId',
                  element: <CustomerProfile />,
                },
                {
                  path: '/customer-lookup/:customerId/changelog',
                  element: <CustomerChangelog />,
                  handle: {
                    title: 'Changelog',
                  },
                },
                {
                  path: '/customer-lookup/:customerId/order/:orderId',
                  element: Layout,
                  handle: {
                    title: 'orderId',
                  },
                  children: [
                    {
                      path: '/customer-lookup/:customerId/order/:orderId',
                      element: <OrderDetailFull />,
                    },
                    {
                      path: '/customer-lookup/:customerId/order/:orderId/refund',
                      element: <CustomerRefund />,
                      handle: {
                        title: 'Refund Order',
                      },
                    },
                    {
                      path: '/customer-lookup/:customerId/order/:orderId/product/:productCode/claim',
                      element:
                        isAuthenticated &&
                        (isTeamMember() || isAdmin() || isOpsAdmin() || isAllModuleAccessUser()) ? (
                          <ClaimForm />
                        ) : (
                          <Unauthorized />
                        ),
                      handle: {
                        title: 'Create Claim',
                      },
                    },
                    {
                      path: '/customer-lookup/:customerId/order/:orderId/product/:productCode/claim/:claimId',
                      element:
                        isAuthenticated &&
                        (isTeamMember() || isAdmin() || isOpsAdmin() || isAllModuleAccessUser()) ? (
                          <ClaimForm />
                        ) : (
                          <Unauthorized />
                        ),
                      handle: {
                        title: 'Edit Claim',
                      },
                    },
                    {
                      path: '/customer-lookup/:customerId/order/:orderId/:fulfillmentId/split-fulfillment',
                      element: <SplitFulfillment />,
                      handle: {
                        title: 'Split Fulfillment',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: '/discount-lookup',
          element: <DiscountLookup />,
          handle: {
            title: 'Discount Lookup',
          },
          children: [
            {
              path: '/discount-lookup/:discountId',
              element: <DiscountLookup />,
              handle: {
                title: 'View Discount',
              },
            },
          ],
        },
        {
          path: '/discounts',
          element:
            isAuthenticated && (isAdmin() || isOpsAdmin() || isAllModuleAccessUser()) ? (
              Layout
            ) : (
              <Unauthorized />
            ),
          handle: {
            title: 'Manage Discounts',
          },
          children: [
            {
              path: '/discounts',
              element: <Discounts />,
            },
            {
              path: '/discounts/create-discount',
              element: <CreateDiscount />,
              handle: {
                title: 'Create Discount',
              },
            },
            {
              path: '/discounts/:discountId',
              element: <UpdateDiscountContainer />,
              handle: {
                title: 'Update Discount',
              },
            },
            {
              path: '/discounts/changelog',
              element: <SystemChangelog category={Category.Discounts} />,
              handle: {
                title: 'Changelog',
              },
            },
          ],
        },
        {
          path: '/promotions',
          element: isAuthenticated && isAllModuleAccessUser() ? Layout : <Unauthorized />,
          handle: {
            title: 'Manage Promotions',
          },
          children: [
            {
              path: '/promotions',
              element: <Promotions />,
            },
            {
              path: '/promotions/create-promotion',
              element: <CreatePromotion />,
              handle: {
                title: 'Create Promotion',
              },
            },
            {
              path: '/promotions/:promotionId',
              element: <ViewPromotion />,
              handle: {
                title: 'View Promotion',
              },
            },
          ],
        },
        {
          path: '/manage-data',
          element:
            isAuthenticated && (isOpsAdmin() || isAllModuleAccessUser()) ? (
              Layout
            ) : (
              <Unauthorized />
            ),
          handle: {
            title: 'Manage Data',
          },
          children: [
            {
              path: '/manage-data',
              element: <ManageData />,
            },
          ],
        },
        {
          path: '/items',
          element:
            isAuthenticated && (isOpsAdmin() || isAllModuleAccessUser()) ? (
              Layout
            ) : (
              <Unauthorized />
            ),
          handle: {
            title: 'Manage Items',
          },
          children: [
            {
              path: '/items',
              element: <Items />,
            },
            {
              path: '/items/create-item',
              element: <CreateItem />,
              handle: {
                title: 'Create Item',
              },
            },
            {
              path: '/items/changelog',
              element: <SystemChangelog category={Category.Items} />,
              handle: {
                title: 'Changelog',
              },
            },
          ],
        },
        {
          path: '/product-items',
          element:
            isAuthenticated && (isOpsAdmin() || isAllModuleAccessUser()) ? (
              Layout
            ) : (
              <Unauthorized />
            ),
          handle: {
            title: 'Manage Product-Items',
          },
          children: [
            {
              path: '/product-items',
              element: <ProductItems />,
            },
            {
              path: '/product-items/create-product-items',
              element: <ProductItemsMappingForm />,
              handle: {
                title: 'Create Product-Item Mapping',
              },
            },
            {
              path: '/product-items/update-product-items/:productId',
              element: <ProductItemsMappingForm />,
              handle: {
                title: 'Update Product-Item Mapping',
              },
            },
            {
              path: '/product-items/changelog',
              element: <SystemChangelog category={Category.ProductItems} />,
              handle: {
                title: 'Changelog',
              },
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={routes} />;
};

const BarkApp = () => (
  <AuthProvider>
    <BarkRouter />
  </AuthProvider>
);

export default BarkApp;
