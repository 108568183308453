import { ProductType } from '../../pages/types';

// Define the issue types based on product type
export const getIssueOptions = (productType: ProductType, isFrozen: boolean) => {
  if (productType === ProductType.MEAL && !isFrozen) {
    // UnKibble product
    return [
      { label: 'Packaging or fill issue', value: 'PACKAGING_FILL_ISSUE' },
      { label: 'Foreign Contamination', value: 'FOREIGN_CONTAMINATION' },
      { label: 'Pellet issue', value: 'PELLET_ISSUE' },
      { label: 'Inclusion issue', value: 'INCLUSION_ISSUE' },
      { label: 'Unknown', value: 'UNKNOWN' },
    ];
  } else if (productType === ProductType.MEAL && isFrozen) {
    // Fresh product
    return [
      { label: 'Packaging Issue', value: 'PACKAGING_ISSUE' },
      { label: 'Foreign Contamination', value: 'FOREIGN_CONTAMINATION' },
      { label: 'Color or smell issue', value: 'COLOR_SMELL_ISSUE' },
      { label: 'Texture issue', value: 'TEXTURE_ISSUE' },
      { label: 'Unknown', value: 'UNKNOWN' },
    ];
  } else if (
    productType !== ProductType.MEAL &&
    [ProductType.SNACK, ProductType.SUPPLEMENT].includes(productType)
  ) {
    // Non-Meal consumable product
    return [
      { label: 'Packaging or fill issue', value: 'PACKAGING_FILL_ISSUE' },
      { label: 'Foreign Contamination', value: 'FOREIGN_CONTAMINATION' },
      { label: 'Color or smell issue', value: 'COLOR_SMELL_ISSUE' },
      { label: 'Shape or texture issue', value: 'SHAPE_TEXTURE_ISSUE' },
      { label: 'Unknown', value: 'UNKNOWN' },
    ];
  } else {
    // Non-Meal non-consumable product
    return [
      { label: 'Other', value: 'OTHER' },
      { label: 'Unknown', value: 'UNKNOWN' },
    ];
  }
};

// Define the sub-issue types based on the selected issue and product type
export const getSubIssueOptions = (
  issueType: string,
  productType: ProductType,
  isFrozen: boolean
) => {
  if (issueType === 'UNKNOWN') {
    return [];
  }

  if (productType === ProductType.MEAL && !isFrozen) {
    // UnKibble product
    switch (issueType) {
      case 'PACKAGING_FILL_ISSUE':
        return [
          { label: 'Bag not sealed shut (heat seal failed)', value: 'BAG_NOT_SEALED' },
          { label: "Reusable ziplock won't reseal (zipper failed)", value: 'ZIPLOCK_FAILED' },
          { label: 'Bag slashed, punctured, or torn', value: 'BAG_DAMAGED' },
          { label: 'Bag appears underfilled', value: 'BAG_UNDERFILLED' },
          { label: 'Bloated bag (excess gas/air in bag)', value: 'BLOATED_BAG' },
          { label: 'Other packaging or fill issue', value: 'OTHER', requiresNotes: true },
          { label: 'Unknown', value: 'UNKNOWN' },
        ];
      case 'FOREIGN_CONTAMINATION':
        return [
          { label: 'Plastic', value: 'PLASTIC' },
          { label: 'Metal', value: 'METAL' },
          { label: 'Paper', value: 'PAPER' },
          { label: 'Wood', value: 'WOOD' },
          { label: 'Rock', value: 'ROCK' },
          { label: 'Glass', value: 'GLASS' },
          { label: 'Rubber', value: 'RUBBER' },
          { label: 'Mold', value: 'MOLD' },
          { label: 'Insect', value: 'INSECT' },
          { label: 'Feather', value: 'FEATHER' },
          {
            label: 'Inedible animal ingredient (bone, gristle, sinew, cartilage, chunk of fat)',
            value: 'INEDIBLE_ANIMAL_INGREDIENT',
          },
          { label: 'Other foreign contaminant', value: 'OTHER', requiresNotes: true },
          { label: 'Unknown', value: 'UNKNOWN' },
        ];
      case 'PELLET_ISSUE':
        return [
          { label: 'Smells off', value: 'SMELLS_OFF' },
          { label: 'Multiple colored pellets in single bag', value: 'MULTIPLE_COLORS' },
          { label: 'Different color than usual', value: 'DIFFERENT_COLOR' },
          { label: 'Pellets too small', value: 'TOO_SMALL' },
          { label: 'Pellets too large', value: 'TOO_LARGE' },
          { label: 'Pellets stuck together', value: 'STUCK_TOGETHER' },
          { label: 'Excessive crumbles', value: 'EXCESSIVE_CRUMBLES' },
          { label: 'Pellets too hard', value: 'TOO_HARD' },
          { label: 'Pellets too soft', value: 'TOO_SOFT' },
          { label: 'Other pellet issue', value: 'OTHER', requiresNotes: true },
          { label: 'Unknown', value: 'UNKNOWN' },
        ];
      case 'INCLUSION_ISSUE':
        return [
          { label: 'Too few inclusions', value: 'TOO_FEW' },
          { label: 'Too many inclusions', value: 'TOO_MANY' },
          { label: 'All inclusions sunk to the bottom', value: 'SUNK_BOTTOM' },
          { label: 'All inclusions on top of bag', value: 'TOP_OF_BAG' },
          { label: 'Inclusions stuck together', value: 'STUCK_TOGETHER' },
          { label: 'Inclusions too small', value: 'TOO_SMALL' },
          { label: 'Inclusions too large', value: 'TOO_LARGE' },
          { label: 'Inclusions too hard', value: 'TOO_HARD' },
          { label: 'Inclusions too soft', value: 'TOO_SOFT' },
          { label: 'Other inclusion issue', value: 'OTHER', requiresNotes: true },
          { label: 'Unknown', value: 'UNKNOWN' },
        ];
      default:
        return [];
    }
  } else if (productType === ProductType.MEAL && isFrozen) {
    // Fresh product
    switch (issueType) {
      case 'PACKAGING_ISSUE':
        return [
          { label: 'Pack seal failed (film peeled off)', value: 'PACK_SEAL_FAILED' },
          { label: 'Pack slashed, punctured, or torn', value: 'PACK_DAMAGED' },
          { label: 'Leaking pack (no visible seal issue or tear)', value: 'LEAKING_PACK' },
          { label: 'Bloated bag (excess gas/air in pack)', value: 'BLOATED_PACK' },
          { label: 'Other packaging issue', value: 'OTHER', requiresNotes: true },
          { label: 'Unknown', value: 'UNKNOWN' },
        ];
      case 'FOREIGN_CONTAMINATION':
        return [
          { label: 'Plastic', value: 'PLASTIC' },
          { label: 'Metal', value: 'METAL' },
          { label: 'Paper', value: 'PAPER' },
          { label: 'Wood', value: 'WOOD' },
          { label: 'Rock', value: 'ROCK' },
          { label: 'Glass', value: 'GLASS' },
          { label: 'Rubber', value: 'RUBBER' },
          { label: 'Mold', value: 'MOLD' },
          { label: 'Insect', value: 'INSECT' },
          {
            label:
              'Inedible animal ingredient (ie. bone, gristle, sinew, cartilage, chunk of fat, etc.)',
            value: 'INEDIBLE_ANIMAL',
          },
          { label: 'Other foreign contaminant', value: 'OTHER', requiresNotes: true },
          { label: 'Unknown', value: 'UNKNOWN' },
        ];
      case 'COLOR_SMELL_ISSUE':
        return [
          { label: 'Smells off', value: 'SMELLS_OFF' },
          { label: 'Different color than another pack received', value: 'DIFFERENT_COLOR' },
          { label: 'Other color or smell issue', value: 'OTHER', requiresNotes: true },
          { label: 'Unknown', value: 'UNKNOWN' },
        ];
      case 'TEXTURE_ISSUE':
        return [
          { label: 'Food is slimy', value: 'SLIMY' },
          { label: 'Food is too dry', value: 'TOO_DRY' },
          {
            label: "Food looks too blended (i.e. can't see individual ingredients)",
            value: 'TOO_BLENDED',
          },
          { label: 'Other texture issue', value: 'OTHER', requiresNotes: true },
          { label: 'Unknown', value: 'UNKNOWN' },
        ];
      default:
        return [];
    }
  } else if ([ProductType.SNACK, ProductType.SUPPLEMENT].includes(productType)) {
    // Non-Meal consumable product
    switch (issueType) {
      case 'PACKAGING_FILL_ISSUE':
        return [
          {
            label:
              'Bad/container not sealed shut (ie. heat seal failed, lid not secure/missing, etc.)',
            value: 'NOT_SEALED',
          },
          { label: "Reusable ziplock won't close (zipper failed)", value: 'ZIPLOCK_FAILED' },
          { label: 'Bag/container slashed, punctured or torn', value: 'CONTAINER_DAMAGED' },
          { label: 'Bag/container appears underfilled', value: 'UNDERFILLED' },
          { label: 'Bloated bag (excess gas/air in bag)', value: 'BLOATED_BAG' },
          { label: 'Other packaging or fill issue', value: 'OTHER', requiresNotes: true },
          { label: 'Unknown', value: 'UNKNOWN' },
        ];
      case 'FOREIGN_CONTAMINATION':
        return [
          { label: 'Plastic', value: 'PLASTIC' },
          { label: 'Metal', value: 'METAL' },
          { label: 'Paper', value: 'PAPER' },
          { label: 'Wood', value: 'WOOD' },
          { label: 'Rock', value: 'ROCK' },
          { label: 'Glass', value: 'GLASS' },
          { label: 'Rubber', value: 'RUBBER' },
          { label: 'Mold', value: 'MOLD' },
          { label: 'Insect', value: 'INSECT' },
          { label: 'Feather', value: 'FEATHER' },
          {
            label:
              'Inedible animal ingredient (ie. bone, gristle, sinew, cartilage, chunk of fat, etc.)',
            value: 'INEDIBLE_ANIMAL',
          },
          { label: 'Other foreign contaminant', value: 'OTHER', requiresNotes: true },
          { label: 'Unknown', value: 'UNKNOWN' },
        ];
      case 'COLOR_SMELL_ISSUE':
        return [
          { label: 'Smells off', value: 'SMELLS_OFF' },
          { label: 'Multiple colored pieces in single bag/container', value: 'MULTIPLE_COLORS' },
          { label: 'Different color than usual', value: 'DIFFERENT_COLOR' },
          { label: 'Other color or smell issue', value: 'OTHER', requiresNotes: true },
          { label: 'Unknown', value: 'UNKNOWN' },
        ];
      case 'SHAPE_TEXTURE_ISSUE':
        return [
          { label: 'Pieces too small', value: 'TOO_SMALL' },
          { label: 'Pieces too large', value: 'TOO_LARGE' },
          { label: 'Pieces stuck together', value: 'STUCK_TOGETHER' },
          { label: 'Excessive crumbles', value: 'EXCESSIVE_CRUMBLES' },
          { label: 'Pieces too hard', value: 'TOO_HARD' },
          { label: 'Pieces too soft', value: 'TOO_SOFT' },
          { label: 'Other shape or texture issue', value: 'OTHER', requiresNotes: true },
          { label: 'Unknown', value: 'UNKNOWN' },
        ];
      default:
        return [];
    }
  } else {
    return [];
  }
};
