import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from './Button';
import { MappedFulfillment } from './Claim/types';
import { ENABLE_CLAIMS_FEATURE } from '../api';

import { Customer } from '@/pages/types';

interface ClaimsColumnProps {
  claimId: string | null;
  onDeleteClick: (claimId: string) => void;
  userHasDeleteAccess: boolean;
  product: MappedFulfillment;
  customer: Customer;
}

export const ClaimsColumn = ({
  claimId,
  onDeleteClick,
  userHasDeleteAccess,
  product,
  customer,
}: ClaimsColumnProps) => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname.replace(/\/$/, '');

  const navigateToCreateClaim = () => {
    navigate(`${pathname}/product/${product.code}/claim`);
  };

  const navigateToEditClaim = (claimId: string) => {
    navigate(`${pathname}/product/${product.code}/claim/${claimId}`);
  };

  if (!ENABLE_CLAIMS_FEATURE) {
    return null;
  }

  return (
    <div className="flex items-center justify-center">
      {claimId ? (
        <div className="flex flex-col space-y-2">
          <Button
            label="Edit Claim"
            onClick={() => navigateToEditClaim(claimId)}
            className="px-[2px] py-[2px]"
            variant="secondary"
            textClassName="text-sm font-normal"
          />
          {userHasDeleteAccess && (
            <Button
              label="Delete Claim"
              onClick={() => onDeleteClick(claimId)}
              className="px-[2px] py-[2px] text-red-600"
              variant="secondary"
              textClassName="text-sm font-normal text-red-600"
            />
          )}
        </div>
      ) : (
        <Button
          label="Create Claim"
          onClick={navigateToCreateClaim}
          className="px-[2px] py-[2px]"
          variant="secondary"
          textClassName="text-sm font-normal"
        />
      )}
    </div>
  );
};
