import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import { OneTimeOrderContext } from '.';
import { InlineSpinner } from '../../assets/Spinner';
import { formatingOptions } from '../../formatingOptions';
import { filterProductsByPetMealType } from '../../orderUtils';
import { Option } from '../../pages/types';
import { Button } from '../Button';
import { Dropdown } from '../Dropdown';
import { MultiSelectDropdown } from '../MultiSelectDropdown';

export const OneTimeOrderStep1 = () => {
  const {
    dogs,
    setSelectedDog,
    fullProducts,
    selectedProducts,
    setSelectedProducts,
    isLoading,
    isEverythingSelected,
    navigateBetweenSteps,
    selectedDog,
  } = useContext(OneTimeOrderContext);
  const formattedProducts = formatingOptions(fullProducts, { value: 'id', label: 'code' });
  const [filteredOptions, setFilteredOptions] = useState(formattedProducts);

  useEffect(() => {
    if (!filteredOptions.length && formattedProducts.length) {
      setFilteredOptions(formattedProducts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!formattedProducts?.length]);

  const handleSelectProducts = (updatedProducts: Option[]) => {
    setSelectedProducts(updatedProducts);
    setFilteredOptions(filterProductsByPetMealType(fullProducts, updatedProducts));
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full pr-2 pt-3 flex flex-col gap-2">
        <div className="text-md font-semibold text-left">
          <h3>Which dog would you like to create an order for?</h3>
        </div>
        <div className="mb-2 max-w-[80%]">
          <Dropdown
            options={dogs}
            selectedOption={selectedDog || { value: '', label: 'Select dog' }}
            onSelect={setSelectedDog}
          />
        </div>
      </div>
      <div className="w-full pr-2 flex flex-col gap-2">
        <div className="text-md font-semibold text-left">
          <h3>Which product(s) should we include in this one-time order?</h3>
        </div>
        <div className="mb-2 flex relative max-w-[80%]">
          <MultiSelectDropdown
            options={filteredOptions}
            selectedOptions={selectedProducts}
            setSelectedOptions={handleSelectProducts}
            placeholder="Start typing a product code"
          />
          {isLoading && (
            <div className="absolute inset-0 bg-black/10 flex items-center justify-center">
              <InlineSpinner />
            </div>
          )}
        </div>
      </div>
      <div className="mt-[45px] w-fill flex justify-center">
        <Button
          isDisabled={!isEverythingSelected}
          label="Continue"
          variant={isEverythingSelected ? 'primary' : 'secondary'}
          onClick={navigateBetweenSteps}
        />
      </div>
    </div>
  );
};
