import React, { useState, useRef } from 'react';

import { ClaimType, ClaimTypeOptions } from './constants';
import { ClaimFormData } from './types';
import { DownArrow } from '../../assets/DownArrow';
import { useOutsideHandler } from '../../hooks/useOutsideHandler';
import { ConfirmationModal } from '../ConfirmationModal';

interface ClaimTypeSelectDropdownProps {
  formData: ClaimFormData;
  setFormData: (data: ClaimFormData) => void;
  selectedOptions?: ClaimType[];
  isMealProduct: boolean;
  isFrozen: boolean;
  error?: string;
}

const getAvailableClaimTypes = ({
  isMealProduct,
  isFrozen,
}: {
  isMealProduct: boolean;
  isFrozen: boolean;
}) => {
  return ClaimTypeOptions.filter((claimType) => {
    switch (claimType.value) {
      case ClaimType.ROOF:
        return isMealProduct;
      case ClaimType.WARM_BOX:
        return isMealProduct && isFrozen;
      case ClaimType.MISPACK:
        return true;
      case ClaimType.FEEDING_GUIDE:
        return isMealProduct;
      default:
        return true;
    }
  });
};

export const ClaimTypeSelectDropdown = ({
  formData,
  setFormData,
  selectedOptions = [],
  isMealProduct,
  isFrozen,
  error,
}: ClaimTypeSelectDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const options = getAvailableClaimTypes({ isMealProduct, isFrozen });

  const [showTypeChangeModal, setShowTypeChangeModal] = useState(false);
  const [previousClaimTypes, setPreviousClaimTypes] = useState<ClaimType[]>(formData.claimTypes);
  const [pendingClaimTypes, setPendingClaimTypes] = useState<ClaimType[]>([]);

  const getResetFieldsForClaimType = (claimType: ClaimType) => {
    const updates: Partial<ClaimFormData> = {};

    switch (claimType) {
      case ClaimType.ADVERSE_REACTION:
        updates.adverseReactionVetVisitRequired = undefined;
        updates.adverseReactionVetBillRequested = undefined;
        updates.adverseReactionVetBillCost = undefined;
        updates.adverseReactionSymptoms = undefined;
        updates.adverseReactionAdditionalNotes = undefined;
        updates.adverseReactionLotCodes = undefined;
        break;
      case ClaimType.FEEDING_GUIDE:
        updates.feedingGuideIssueType = undefined;
        break;
      case ClaimType.MISPACK:
        updates.mispackHaveInformationOnWhatWasReceived = undefined;
        updates.mispackShouldHaveReceived = undefined;
        updates.mispackActuallyReceived = undefined;
        break;
      case ClaimType.PRODUCT_QUALITY:
        updates.productQualityIssues = undefined;
        break;
      case ClaimType.ROOF:
        updates.roofUnkibbleUsedCorrectScoop = undefined;
        updates.roofUnkibbleFedCorrectNumberOfScoops = undefined;
        updates.roofUnkibbleLeveledOffScoop = undefined;
        updates.roofFreshFedCorrectNumberOfPacks = undefined;
        updates.roofLotCodes = undefined;
        break;
      case ClaimType.WARM_BOX:
        updates.warmBoxFoodInsideSilverBag = undefined;
        updates.warmBoxSilverBagSealed = undefined;
        updates.warmBoxGelPackOrDryIcePresent = undefined;
        break;
    }

    return updates;
  };

  const doFormFieldsHaveValues = (claimType: ClaimType) => {
    switch (claimType) {
      case ClaimType.ADVERSE_REACTION:
        return (
          formData.adverseReactionVetVisitRequired !== undefined ||
          formData.adverseReactionVetBillRequested !== undefined ||
          formData.adverseReactionVetBillCost !== undefined ||
          formData.adverseReactionSymptoms !== undefined ||
          formData.adverseReactionAdditionalNotes !== undefined ||
          formData.adverseReactionLotCodes !== undefined
        );
      case ClaimType.FEEDING_GUIDE:
        return formData.feedingGuideIssueType;
      case ClaimType.MISPACK:
        return (
          formData.mispackHaveInformationOnWhatWasReceived !== undefined ||
          formData.mispackShouldHaveReceived ||
          formData.mispackActuallyReceived
        );
      case ClaimType.PRODUCT_QUALITY:
        return formData.productQualityIssues?.length;
      case ClaimType.ROOF:
        return (
          formData.roofUnkibbleUsedCorrectScoop !== undefined ||
          formData.roofUnkibbleFedCorrectNumberOfScoops !== undefined ||
          formData.roofUnkibbleLeveledOffScoop !== undefined ||
          formData.roofFreshFedCorrectNumberOfPacks !== undefined ||
          formData.roofLotCodes !== undefined
        );
      case ClaimType.WARM_BOX:
        return (
          formData.warmBoxFoodInsideSilverBag ||
          formData.warmBoxSilverBagSealed ||
          formData.warmBoxGelPackOrDryIcePresent
        );
    }
    return false;
  };

  const handleClaimTypesChange = (newClaimTypes: ClaimType[]) => {
    const removedType = previousClaimTypes.find((type) => !newClaimTypes.includes(type));
    const showConfirmationModal = removedType ? doFormFieldsHaveValues(removedType) : false;

    if (showConfirmationModal) {
      setPendingClaimTypes(newClaimTypes);
      setShowTypeChangeModal(true);
    } else {
      setFormData({
        ...formData,
        claimTypes: newClaimTypes,
      });
      setPreviousClaimTypes(newClaimTypes);
    }
  };

  const handleTypeChangeConfirm = () => {
    const removedType = previousClaimTypes.find((type) => !pendingClaimTypes.includes(type));
    if (removedType) {
      const updates = getResetFieldsForClaimType(removedType);
      setFormData({
        ...formData,
        ...updates,
        claimTypes: pendingClaimTypes,
      });
    } else {
      setFormData({
        ...formData,
        claimTypes: pendingClaimTypes,
      });
    }
    setPreviousClaimTypes(pendingClaimTypes);
    setShowTypeChangeModal(false);
  };

  const handleTypeChangeCancel = () => {
    setShowTypeChangeModal(false);
  };

  const wrapperRef = useRef(null);
  useOutsideHandler(wrapperRef, () => {
    setIsOpen(false);
  });

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const newSelectedOptions = selectedOptions.includes(value as ClaimType)
      ? selectedOptions.filter((item) => item !== value)
      : [...selectedOptions, value];

    handleClaimTypesChange(newSelectedOptions as ClaimType[]);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectedLabels = selectedOptions.map((value) => {
    const option = options.find((o) => o.value === value);
    return option ? option.label : '';
  });

  return (
    <div ref={wrapperRef} className="relative inline-block text-left w-full">
      <div className="w-full">
        <button
          onClick={toggleDropdown}
          type="button"
          className="inline-flex w-full px-4 py-2 text-sm font-medium bg-secondary border border-black rounded overflow-x-auto"
        >
          <div className="flex justify-between w-full">
            {selectedLabels.length > 0 ? selectedLabels.join(', ') : 'Select claim types'}
            <DownArrow className="w-5 h-5" />
          </div>
        </button>
        {error && (
          <p className="text-red-500 text-xs mt-1 font-normal w-full overflow-auto">{error}</p>
        )}
      </div>

      {isOpen && (
        <div className="absolute rounded-md border border-black bg-secondary w-full z-50">
          <div>
            {options.map((option) => (
              <label key={option.value} className="px-4 py-2 cursor-pointer items-center flex">
                <input
                  type="checkbox"
                  value={option.value}
                  data-label={option.label}
                  checked={selectedOptions.includes(option.value)}
                  onChange={handleOptionChange}
                  className="form-checkbox h-5 w-5 text-indigo-600 mr-2"
                />
                <span>{option.label}</span>
              </label>
            ))}
          </div>
        </div>
      )}
      {showTypeChangeModal && (
        <ConfirmationModal
          title={`Are you sure you want to remove "${previousClaimTypes
            .filter((type) => !pendingClaimTypes.includes(type))
            .map((type) => options.find((opt) => opt.value === type)?.label)}" from this claim?`}
          message="Please confirm before proceeding, as claim types cannot be restored once deleted."
          confirmLabel="Confirm"
          onConfirm={handleTypeChangeConfirm}
          onCancel={handleTypeChangeCancel}
        />
      )}
    </div>
  );
};
